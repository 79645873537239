import gql from 'graphql-tag'

export default gql`
  query GetBranch($id: Int!) {
    getBranchOffice(id: $id) {
      id
      key
      fullpath
      name
      applicationMode
      code
      address
      city
      phone
      email
      openingHours
      commission
      expedition
      businessRegisterName
      billingCity
      billingResidence
      billingPostalCode
      companyIdNumber
      companyTaxNumber
      billingRegisteredIn
      url
      stampAsset {
        ... on asset {
          id
          fullpath
          filename
          filesize
          type
          mimetype
        }
      }

      employees {
        ... on fieldcollection_emloyee {
          name
          position
          phone
          email
          photo {
            ... on hotspotimage {
              image {
                ... on asset {
                  id
                  fullpath
                  filename
                  filesize
                  type
                  mimetype
                }
              }
            }
          }
        }
      }

      location {
        ... on Geopoint {
          latitude
          longitude
        }
      }

      holidayFrom
      holidayTill
      morningAssemblyTime
      afternoonAssemblyTime
      eveningAssemblyTime
      isWholesale

      workerGroups {
        ... on object_WorkerGroup {
          id
          key
          fullpath
          workerGroupID
          availableMorning
          availableAfternoon
          availableEvening
          certificate

          leadingWorker {
            ... on object_Worker {
              id
              key
              fullpath
              firstName
              lastName
              phone
              email
            }
          }

          workers {
            ... on object_Worker {
              id
              key
              fullpath
              firstName
              lastName
              phone
              email
            }
          }
        }
      }

      apiUsers {
        ... on object_ApiUser {
          id
          key
          fullpath
          name
          email
          passwordResetHash
        }
      }
      parentBranchOffice {
        ... on object_BranchOffice {
          id
          key
          fullpath
        }
      }
    }
  }
`
