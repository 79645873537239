import { rlog, ulog } from '@/common/utils/AppConsole'
import { PimcoreNodeImport, PimcoreNodeStrip } from '@/models/pimcore/PimcoreNode'
import { WorkerExport, Worker } from '@/models/workers/Worker'
import { WorkerGroup } from '@/models/workers/WorkerGroup'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { CreateWorkerResult, DeleteWorkerResult, UpdateWorkerInput, UpdateWorkerResult } from '../../schema/app.graphql.types'
import createWorker from './createWorker'
import deleteWorker from './deleteWorker'
import updateWorker from './updateWorker'

export namespace GQLWorker {

  export namespace Create {

    const mutation = createWorker

    export type Variables = {
      parentId: number
      key: string
      values: UpdateWorkerInput
    }

    export type Data = {
      createWorker: CreateWorkerResult
    }

    export const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.createWorker.success && r.data.createWorker.output
        ? PimcoreNodeImport(r.data.createWorker.output)
        : ulog('e', 'GQLWorker(C) procedure failed:', r)
      )
      .catch(e => ulog('e', 'GQLWorker(C) exception', e))

    export const procedure = async (wg: WorkerGroup, w: Worker) =>
      execute({ parentId: parseInt(wg.id!), key: `${w.lastName}-${w.firstName}`, values: WorkerExport(w) })

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Update {

    const mutation = updateWorker

    export type Variables = {
      fullpath: string
      values: UpdateWorkerInput
    }

    export type Data = {
      updateWorker: UpdateWorkerResult
    }

    export const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.updateWorker.success && r.data.updateWorker.output
        ? PimcoreNodeImport(r.data.updateWorker.output)
        : ulog('e', 'GQLWorker(U) procedure failed:', r)
      )
      .catch(e => ulog('e', 'GQLWorker(U) exception', e))

    export const procedure = async (worker: Worker) =>
      execute({ fullpath: worker.fullpath!, values: WorkerExport(PimcoreNodeStrip(worker)) })

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Delete {

    const mutation = deleteWorker

    export type Variables = {
      fullpath: string,
    }

    export type Data = {
      deleteWorker: DeleteWorkerResult
    }

    export const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.deleteWorker.success
        ? true
        : rlog('e', false, 'GQLWorker(D) procedure failed:', r)
      )
      .catch(e => rlog('e', false, 'GQLWorker(D) exception', e))

    export const procedure = async (worker: Worker) =>
      execute({ fullpath: worker.fullpath! })

  }

}
