import store, { CombinedStates, ModulesNamespaces, RootState } from './root.store'
import { AuthState } from './auth.store'
import { AppDataState } from './appdata.store'
import { ModalState } from './modal.store'
import { CalendarState } from './calendar.store'


const stateGetter = <T, S extends CombinedStates>(namespace: ModulesNamespaces, field: keyof S): T => {
  return namespace === '' ? store.state[field as string] : store.state[namespace][field]
}

export const storeGetter = {

  root: <T = keyof RootState>(field: keyof RootState) => stateGetter<T, RootState>('', field),
  auth: <T = keyof AuthState>(field: keyof AuthState) => stateGetter<T, AuthState>('auth', field),
  appData: <T = keyof AppDataState>(field: keyof AppDataState) => stateGetter<T, AppDataState>('appData', field),
  modal: <T = keyof ModalState>(field: keyof ModalState) => stateGetter<T, ModalState>('modal', field),
  calendar: <T = keyof CalendarState>(field: keyof CalendarState) => stateGetter<T, CalendarState>('calendar', field),

}


const stateGetterFull = <T extends CombinedStates>(namespace: ModulesNamespaces) => store.state[namespace] as T

export const getState = {
  appData: () => stateGetterFull<AppDataState>('appData'),
  auth: () => stateGetterFull<AuthState>('auth'),
  calendar: () => stateGetterFull<CalendarState>('calendar'),
  modal: () => stateGetterFull<ModalState>('modal'),
}
