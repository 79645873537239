import gql from 'graphql-tag'

export default gql`
mutation CreateProtocolData(
  $key: String!,
  $parentId: Int!,
  $input: UpdateProtocolDataInput!,
) {
  createProtocolData(
    key: $key,
    parentId: $parentId,
    input: $input
  ) {
    success
    message
    output {
      id
      key
      fullpath
    }
  }
}
`
