import { FormSelection, get } from '@/common/custom/ts-customization'
import { Maybe, Object_InvoicingData } from '@/api/graphql/schema/app.graphql.types'
import { PimcoreNode } from '../pimcore/PimcoreNode'
import i18nPlugin from '@/plugins/i18n.plugin'

export type InvoicedEntity = 'person' | 'company'
export const InvoicedEntity: FormSelection<InvoicedEntity> = {
  person: { key: 'person', label: 'Fyzická osoba' },
  company: { key: 'company', label: 'Právnická osoba' },
}
export const InvoicedEntityPretty = (key: InvoicedEntity) => key === 'person' ? InvoicedEntity.person.label : InvoicedEntity.company.label

export type TaxLiability = 'transferred' | 'nontransferred'
export const TaxLiability: FormSelection<TaxLiability> = {
  transferred: { key: 'transferred', label: i18nPlugin.t('order.invoice.taxLiabilityTransferred').toString() },
  nontransferred: { key: 'nontransferred', label:  i18nPlugin.t('order.invoice.taxLiabilityNonTransferred').toString() },
}

export enum TaxLiabilityRate {
  rate12 = '12%',
  rate15 = '15%',
  rate21 = '21%',
}

export enum InvoiceVerificationType {
  proforma = 'proforma',
  cash = 'cash',
}

export type PaymentMethod = 'proforma' | 'transfer' | 'cash'
export const PaymentMethod: FormSelection<PaymentMethod> = {
  proforma: { key: 'proforma', label: 'Zálohová faktura' },
  transfer: { key: 'transfer', label: 'Převodem po převzetí' },
  cash: { key: 'cash', label: 'Dobírka hotově nebo kartou' },
}

export type VerificationType = 'birthdate' | 'proforma' | 'cash'
export const VerificationType: FormSelection<VerificationType> = {
  birthdate: { key: 'birthdate', label: 'Datum narození' },
  proforma: { key: 'proforma', label: 'Poslat zálohovou fakturu' },
  cash: { key: 'cash', label: 'Dobírka hotově nebo kartou' },
}

export type VatRate = '12%' | '15%' | '21%'

/** Information related to invoice receiver. */
export type InvoicingData = PimcoreNode & {
  invoicedEntity: InvoicedEntity
  directSell: boolean

  firstName: string
  lastName: string
  verificationType: VerificationType
  /** @dependency verificationType */
  birthdate?: string

  companyName: string
  ico: string
  dic: string

  street: string
  streetNumber: string
  city: string
  postcode: string

  vatRate: VatRate
  liability: TaxLiability
  paymentMethod: PaymentMethod
}
export const InvoicingData = (o?: Maybe<Object_InvoicingData>): InvoicingData => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),
  invoicedEntity: get<InvoicedEntity>(o?.invoicedEntity, InvoicedEntity.person.key),
  directSell: o?.directSell || false,

  firstName: o?.firstName || '',
  lastName: o?.lastName || '',
  verificationType: get<VerificationType>(o?.verificationType, VerificationType.birthdate.key),
  birthdate: o?.birthdate || undefined,

  companyName: o?.companyName || '',
  ico: o?.ico || '',
  dic: o?.dic || '',

  street: o?.street || '',
  streetNumber: o?.streetNumber || '',
  city: o?.city || '',
  postcode: o?.postcode || '',

  vatRate: get<VatRate>(o?.vatRate, TaxLiabilityRate.rate12),
  liability: get<TaxLiability>(o?.liability, TaxLiability.nontransferred.key),
  paymentMethod: get<PaymentMethod>(o?.paymentMethod, PaymentMethod.proforma.key),
})
