import gql from 'graphql-tag'

export default gql`
  mutation CreateWorkerOccupancy(
    $entryType: String!
    $workerGroupId: Int!
    $dateFrom: String!
    $dateTo: String!
    $beginFromMorning: Boolean
    $beginFromEvening: Boolean
    $beginFromAfternoon: Boolean
    $blockingDescription: String
  ) {
    createWorkerOccupancy(
      entryType: $entryType,
      workerGroupId: $workerGroupId,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      beginFromAfternoon: $beginFromAfternoon,
      beginFromMorning: $beginFromMorning,
      beginFromEvening: $beginFromEvening,
      blockingDescription: $blockingDescription
    ) {
      success, output {
        id,
        key,
        fullpath,

        entryType,
        dateFrom,
        dateTo,
        beginFromMorning,
        beginFromEvening,
        beginFromAfternoon,
        blockingDescription
      }
    }
  }
`
