import { Assemblies, Blocking, Maybe, Object_InvoicingData, Vacation } from '@/api/graphql/schema/app.graphql.types'
import { AppDate } from '@/common/utils/AppDate'

import { WorkerGroup } from '../workers/WorkerGroup'
import { DoorParameters } from './../order/configuration/DoorParameters'
import { InvoicingData } from '@/models/order/InvoicingData'
import { BranchOffice } from '../branchoffice/BranchOffice'


/** @unused */
export type WorkerGroupVacation = {
  date: string
  isMorning: boolean
  isAfternoon: boolean
  isEvening: boolean
  isFullday: boolean
  id?: number
}
export const WorkerGroupVacation = (): WorkerGroupVacation => ({
  date: '', isMorning: false, isAfternoon: false, isFullday: false, isEvening: false,
})
export const WorkerGroupVacationsImport = (o?: Maybe<Vacation>[]) => {
  const imported: WorkerGroupVacation[] = []

  if (o?.length) {
    for (const item of o) {
      if (item) {
        const entryCount = AppDate.Util.getDayDiff(item.dateFrom!, item.dateTo!)
        const iterDate = new Date(item.dateFrom!)

        // https://stackoverflow.com/questions/5501581/javascript-new-arrayn-and-array-prototype-map-weirdness
        const entries = [...Array(entryCount)].map((): WorkerGroupVacation => {
          const e: WorkerGroupVacation = {
            date: AppDate.Format.str(iterDate),
            isMorning: item.isMorning || false,
            isAfternoon: item.isAfternoon || false,
            isEvening: item.isEvening || false,
            isFullday: item.isFullday || false,
          }

          AppDate.Util.nextDay(iterDate)
          return e
        })

        imported.push(...entries)
      }
    }
  }

  return imported
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


/** @unused */
export type WorkerGroupBlocking = {
  date: string
  isMorning: boolean
  isAfternoon: boolean
  isEvening: boolean
  /** TODO: rename this to isAfternoon */
  isFullday: boolean
  description: string
  id?: number
}
export const WorkerGroupBlocking = (): WorkerGroupBlocking => ({
  date: '', isMorning: false, isFullday: false, isAfternoon: false, description: '', id: 0, isEvening: false,
})
export const WorkerGroupBlockingsImport = (o?: Maybe<Blocking>[]) => {
  const imported: WorkerGroupBlocking[] = []

  if (o?.length) {
    for (const item of o) {
      if (item) {
        const entryCount = AppDate.Util.getDayDiff(item.dateFrom!, item.dateTo!)
        const iterDate = new Date(item.dateFrom!)

        // https://stackoverflow.com/questions/5501581/javascript-new-arrayn-and-array-prototype-map-weirdness
        const entries = [...Array(entryCount)].map((): WorkerGroupBlocking => {
          const e: WorkerGroupBlocking = {
            date: AppDate.Format.str(iterDate),
            isMorning: item.isMorning || false,
            isAfternoon: item.isAfternoon || false,
            isEvening: item.isEvening || false,
            isFullday: item.isFullday || false,
            description: item.description || '',
          }

          AppDate.Util.nextDay(iterDate)
          return e
        })
        imported.push(...entries)
      }
    }
  }

  return imported
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


export type AssemblyCalendarEntry = {
  fullpath: string

  // data z Order
  isPriority: boolean
  orderNumber: string
  state: string

  // data z Order/ContactData
  firstName: string
  lastName: string

  // data z Order/Configuration
  city: string
  installationDate: string
  installationMorning: boolean
  installationAfternoon: boolean
  installationEvening: boolean
  selectedDoorLabel: string
  selectedDoorLabelExtended: string
  branchOffice?: BranchOffice
  parameters: DoorParameters // will be imported as a string
  invoicingData: InvoicingData
}
export const AssemblyCalendarEntry = (o?: Maybe<Assemblies>): AssemblyCalendarEntry => ({
  fullpath: o?.fullpath || '',
  isPriority: o?.isPriority || false,
  orderNumber: o?.orderNumber || '',
  state: o?.state || '',
  firstName: o?.firstName || '',
  lastName: o?.lastName || '',
  city: o?.city || '',
  installationDate: o?.installationDate || '',
  installationMorning: o?.installationMorning || false,
  installationAfternoon: o?.installationAfternoon || false,
  installationEvening: o?.installationEvening || false,
  selectedDoorLabel: o?.selectedDoorLabel || '',
  selectedDoorLabelExtended: o?.selectedDoorLabelExtended || '',
  branchOffice: o?.branchOffice ? BranchOffice(o.branchOffice) : undefined,
  parameters: DoorParameters(o?.parameters),
  invoicingData: InvoicingData(o?.invoicingData as Object_InvoicingData),
})


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


export type AssemblyCalendarParams = {
  /** YYYY-MM-DD */
  from: string
  /** YYYY-MM-DD */
  to: string

  branchOfficeId?: number
  workerGroupId?: number
}

export type AssemblyCalendarViewModel = {
  [workerGroupID: string]: {
    workerGroup: WorkerGroup
    assemblies: AssemblyCalendarEntry[]
    blockings: WorkerGroupBlocking[]
    vacations: WorkerGroupVacation[]
  }
}
// export const AssemblyCalendarViewModel = (o?: Maybe<AssemblyCalendarEdge>[]) => {
//   const map: AssemblyCalendarViewModel = {}
//   if (!o) return map

//   for (const edge of o) {
//     if (!edge) continue

//     const wg = WorkerGroup(edge.workerGroup)
//     const a: AssemblyCalendarEntry[] = []
//     const b: WorkerGroupBlocking[] = []
//     const v: WorkerGroupVacation[] = []

//     if (edge.assemblies?.length) for (const i of edge.assemblies) a.push(AssemblyCalendarEntry(i))
//     if (edge.blockings?.length) b.push(...WorkerGroupBlockingsImport(edge.blockings))
//     if (edge.vacations?.length) v.push(...WorkerGroupVacationsImport(edge.vacations))

//     if (!map[wg.workerGroupID]) {
//       map[wg.workerGroupID] = {
//         workerGroup: wg,
//         assemblies: a,
//         blockings: b,
//         vacations: v,
//       }
//     }
//     else {
//       map[wg.workerGroupID].assemblies.push(...a)
//       map[wg.workerGroupID].blockings.push(...b)
//       map[wg.workerGroupID].vacations.push(...v)
//     }
//   }

//   return map
// }
