import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { rlog } from '@/common/utils/AppConsole'
import { PimcoreAsset, PimcoreAssetType } from '@/models/pimcore/PimcoreAsset'
import { CreateAssetResult, DeleteAssetResult } from '../schema/app.graphql.types'
import createAsset from './pimcoreAsset/createAsset'
import createAssetId from './pimcoreAsset/createAssetId'
import deleteAsset from './pimcoreAsset/deleteAsset'


export namespace GQLAsset {

  export type Error = 'FILE_EXISTS' | 'NONEXISTENT' | 'UNKNOWN'
  export const isError = (o: PimcoreAsset | Error): o is Error => typeof o === 'string'

  export namespace Read {

    const query = gql`
      query QueryAsset($fullpath: String!) {
        getAsset(fullpath: $fullpath) {
          id, fullpath, filename, type, mimetype, filesize, data
        }
      }
    `

    export type Variables = {
      fullpath: string
    }

    export type Data = {
      getAsset?: PimcoreAsset
    }

    export const execute = (variables: Variables) => appClient
      .query<Data, Variables>({ query, variables })
      .then(r => r.data.getAsset
        ? PimcoreAsset(r.data.getAsset)
        : 'NONEXISTENT' as Error
      )
      .catch(e => rlog<Error>('e', 'NONEXISTENT', `QueryAsset with fullpath=[${variables.fullpath}] does not exist`, e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Create {

    export type Variables = {
      filename: string

      type: PimcoreAssetType
      /** Base64 encoded string containing the data */
      data: string

      /** Path that should be used in terms of Pimcore backend, and as a GQL createAsset mutation variable parameter */
      path?: string

      /** optional swap for fullpath */
      parentId?: number
    }

    export type Data = {
      createAsset: CreateAssetResult
    }

    export const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation: variables.parentId ? createAssetId : createAsset, variables })
      .then(r => r?.data?.createAsset.success
        ? PimcoreAsset(r.data.createAsset.assetData)
        : rlog<Error>('e', 'UNKNOWN', 'MutateAsset(C) failed', r)
      )
      .catch(e => rlog<Error>('e', 'UNKNOWN', 'MutateAsset(C) exception:', e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Delete {

    export type Variables = {
      id: number
      fullpath?: string
    }

    export type Data = {
      deleteAsset: DeleteAssetResult
    }

    export const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation: deleteAsset, variables })
      .then(r => r?.data?.deleteAsset.success
        ? r
        : rlog<Error>('e', 'UNKNOWN', 'DeleteAsset(C) failed', r)
      )
      .catch(e => rlog<Error>('e', 'UNKNOWN', 'DeleteAsset(C) exception:', e))

    }


  export namespace Update {}

}
