import { APIGet, isAPIError } from '@/common/utils/REST'
import { endpoints } from '@/common/constants/endpoints'
import { ConfiguratorAPI } from '@/api/interfaces/static.api.interface'
import { DoorConfigurator } from '@/models/configurator/DoorConfigurator'
import { PricingConfigurator } from '@/models/configurator/PricingConfigurator'
import { clog } from '@/common/utils/AppConsole'

export const IConfiguratorAPI: ConfiguratorAPI = {

  main: async () => {
    const r = await APIGet<DoorConfigurator>(endpoints.assets.config('configurator'))
    return isAPIError(r) ? undefined : r
  },

  prices: async () => {
    const r = await APIGet<PricingConfigurator>(endpoints.assets.config('prices'))
    return isAPIError(r) ? undefined : r
  },

  main_order: async (assetpath: string) => {
    const r = await APIGet<DoorConfigurator>(endpoints.assets.config_order(assetpath))
    return isAPIError(r) ? undefined : r
  },

  prices_order: async (assetpath: string) => {
    const r = await APIGet<PricingConfigurator>(endpoints.assets.config_order(assetpath))
    return isAPIError(r) ? undefined : r
  },

  all: () =>
    new Promise(resolve => {
      // search localstorage
      // if (false) {
      //   resolve(configurators)
      //   return
      // }

      // fetch new

      Promise.all([IConfiguratorAPI.main(), IConfiguratorAPI.prices()])
        .then(r => resolve({ main: r[0], prices: r[1] }))
        .catch(e => {
          clog(e)
          resolve(undefined)
        })
    }),

  all_order: (mainpath: string, pricespath: string) =>
    new Promise(resolve => {
      Promise.all([IConfiguratorAPI.main_order(mainpath), IConfiguratorAPI.prices_order(pricespath)])
        .then(r => resolve({ main: r[0], prices: r[1] }))
        .catch(e => {
          clog(e)
          resolve(undefined)
        })
    }),

}
