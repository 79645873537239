import { ACCESS_TOKEN_EXPIRATION_MS } from '@/common/constants/values'


export namespace AuthToken {

  export const getExpirationTimestamp = () => new Date().getTime() + ACCESS_TOKEN_EXPIRATION_MS

  export const isExpired = (ms: number) => new Date() > new Date(ms)

  export const getRemainingTime = (ms: number) => new Date(ms).getTime() - new Date().getTime()

}
