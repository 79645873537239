import { GQLWorkerOccupancy } from '@/api/graphql/app/workerOccupancy/GQLWorkerOccupancy'
import { PimcoreNode } from '../pimcore/PimcoreNode'
import { WorkerGroup } from './WorkerGroup'


export type WorkerOccupationType = 'blocking' | 'vacation' | 'unset'

export enum OccupationType {
  BLOCKING = 'blocking',
  VACATION = 'vacation',
  UNSET = 'unset',
}

/** use this */
export type WorkerOccupation = PimcoreNode & {

  entryType: WorkerOccupationType
  workerGroup: WorkerGroup

  dateFrom: string
  dateTo: string

  beginFromMorning: boolean
  beginFromAfternoon: boolean
  beginFromEvening: boolean

  blockingDescription: string

}
export const WorkerOccupation = (o?: any): WorkerOccupation => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),
  entryType: o?.entryType || 'unset',
  workerGroup: WorkerGroup(o?.workerGroup),
  dateFrom: o?.dateFrom || '',
  dateTo: o?.dateTo || '',
  beginFromMorning: o?.beginFromMorning || false,
  beginFromAfternoon: o?.beginFromAfternoon || false,
  beginFromEvening: o?.beginFromEvening || false,
  blockingDescription: o?.blockingDescription ||  '',
})

export const WorkerOccupationExport = (o: WorkerOccupation): GQLWorkerOccupancy.Create.Variables => ({
  entryType: o.entryType,
  workerGroupId: parseInt(o.workerGroup.id!),
  dateFrom: o.dateFrom,
  dateTo: o.dateTo,
  beginFromMorning: o.beginFromMorning,
  beginFromAfternoon: o.beginFromAfternoon,
  beginFromEvening: o.beginFromEvening,
  blockingDescription: o.blockingDescription,
})
