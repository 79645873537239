import { Nullable } from '@/common/custom/ts-customization'
import { PimcoreImportable } from './PimcoreNode'

export type PimcoreRelationType = 'object' | 'asset' | 'document'

export type PimcoreRelation = {
  id: number
  type: PimcoreRelationType
}
export const PimcoreRelation = (type: PimcoreRelationType, o?: PimcoreImportable): Nullable<PimcoreRelation> => (
  !o || !o?.id
    ? undefined
    : { id: parseInt(o.id!), type }
)
