import { GQLBranchOffice } from '@/api/graphql/app/branch/GQLBranchOffice'
import { BranchesAPI } from '@/api/interfaces/database/branches.api.interface'
import { MutateBranchOffice } from '@/api/graphql/app/MutateBranchOffice'


export const IBranchesAPI: BranchesAPI = {
  list: GQLBranchOffice.List.procedure,
  read: GQLBranchOffice.Get.procedure,
  update: MutateBranchOffice.Update.procedure,
  create: MutateBranchOffice.Create.procedure,
}
