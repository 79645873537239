import { appClient } from '@/plugins/vue-apollo.plugin'
import { rlog, ulog } from '@/common/utils/AppConsole'
import { OrderImport } from '@/models/order/Order'
import { Object_Order, OrderConnection } from '../../schema/app.graphql.types'
import getOrder from './getOrder'
import getOrderListing from './getOrderListing'
import { Summary } from '@/models/customview/Summary'

/** TODO: import code from QueryOrderList */
export namespace GQLOrder {

  export namespace Get {

    const query = getOrder

    export type Variables = {
      fullpath: string
    }

    export type Data = {
      getOrder: Object_Order
    }

    export const execute = async (variables: Variables) => appClient
      .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getOrder ? OrderImport(r.data.getOrder) : ulog('e', 'GQLOrder (R) failed', r))
      .catch(e => ulog('e', 'GQLOrderDetail exception', e))

  }


  export namespace List {

    const query = getOrderListing

    export type Variables = {
      first?: number
      after?: number
      sortBy?: string[]
      /** Sort by ASC or DESC, use the same position as the sortBy argument for each column to sort by */
      sortOrder?: ('ASC' | 'DESC')[]
      filter?: string | null
    }

    export type Data = {
      getOrderListing: OrderConnection
    }

    export const execute = async (variables: Variables) => appClient
      .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getOrderListing.edges != null
        ? r.data.getOrderListing.edges.map(i => Summary(i?.node))
        : []
      )
      .catch(e => rlog<Summary[]>('e', [], 'GQLOrder(L) exception', e))

  }

}
