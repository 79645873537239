export const useCastObject = () => {
  function castObject<T>(obj: Record<string, any>, typeExample: T): T {
    const castObject = {} as T
    for (const key in obj) {
      if (key in typeExample) {
        castObject[key] = obj[key]
      }
    }
    return castObject
  }

  return {
    castObject,
  }
}
