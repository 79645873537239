/** ... */
export interface AdditionalService {
  /**  */
  key: string
  name: string
  pcs: number

  /** original price WITHOUT VAT, passed from price configurator */
  originalPrice: number

  /** serves as DISPLAY PRICE */
  price: string

  /** Added as helper variable to determine whether the price was edited manually */
  manualMod?: boolean

  multi: boolean

  disabled: boolean

  dependency?: string
  condition?: string[]

  // dependencies?: {
  //   field: string
  //   conditions: string[]
  // }[]

  subgroup?: string[]

  /** @src/models/order/payload :: OrderConfigurationData - specialny field ktory indikuje ci ma vobec zaratavat tuto polozku */
  isOverlapping?: boolean

  dependencies?: AdditionalServiceDependency[]

}

export interface AdditionalServiceDependency {
  key: string
  value: string
}

export const AdditionalServiceDependency = (
  key: string,
  value: string
): AdditionalServiceDependency => ({
  key,
  value,
})

export const AdditionalService = (
  key: string,
  name: string,
  originalPrice: number,
  price?: string,
  multi?: boolean,
  disabled?: boolean,
  dependency?: string,
  condition?: string[],
  subgroup?: string[],
  isOverlapping?: boolean,
  dependencies?: AdditionalServiceDependency[],
): AdditionalService => ({
  key,
  name,
  pcs: 0,
  originalPrice,
  price: price || originalPrice.toString(),
  multi: multi || false,
  disabled: disabled || false,
  dependency,
  condition,
  subgroup,
  isOverlapping: isOverlapping || false,
  dependencies,
  manualMod: false,
})

export const AdditionalServiceImport = (s?: string | null): AdditionalService[] => s ? JSON.parse(s) : []
