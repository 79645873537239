// ...

import { Maybe } from '@/api/graphql/schema/generic.types'

export enum DATETYPE {
  week = 'week',
  month = 'month',
  year = 'year',
}

export const padTwoPlaceDigit = (value: number, addConstant = 0) => {
  /** new value */
  const nv = value + addConstant
  return nv > 9 ? nv.toString() : `0${nv.toString()}`
}

/** TS Date class namespace extension. */
export namespace AppDate {
  /** Date object with adjusted local timezone offset. */
  export const now = () => new Date()// new Date(Date.now() - new Date().getTimezoneOffset() * 60000)

  /** Time in milliseconds since 1.1.1970 00:00:00 */
  export const stamp = (date?: Date) => (date ? date.getTime() : now().getTime())

  /** Extract into string-based structure */
  export const struct = (date?: Date) => {
    const d = date || new Date()
    return {
      year: d.getFullYear().toString(),
      yearShort: d.getFullYear().toString().substring(2, 4),
      month: padTwoPlaceDigit(d.getMonth(), 1),
      day: padTwoPlaceDigit(d.getDate()),
      hours: padTwoPlaceDigit(d.getHours()),
      mins: padTwoPlaceDigit(d.getMinutes()),
    }
  }

  export namespace Format {

    /** FORMAT: 2022-02-19 */
    export const str = (date?: Date) => {
      const s = struct(date)
      return `${s.year}-${s.month}-${s.day}`
    }

    /** FORMAT: 19. 02. 2022 */
    export const pretty = (date?: Date) => {
      const d = (date || now()).toLocaleString('cs-CZ')
      return d.substring(0, d.lastIndexOf(' '))
    }

    /** FORMAT:  */
    export const prettyFull = (date?: Date) => {
      const d = (date || now()).toLocaleString('cs-CZ')
      return d.substring(0, d.lastIndexOf(':'))
    }

  }

  export namespace Pimcore {

    /** Format: YYYY-MM-DD HH:MM:SS */
    export const Export = (date?: Date) => {
      const s = struct(date)
      return `${s.year}-${s.month}-${s.day} ${s.hours}:${s.mins}:00`
    }

    /** Expected Format: (YYYY-MM-DD | YYYY-MM-DD HH:MM:SS) → Date */
    export const Import = (str: string) => new Date(str.substring(0, 20))

    /** Expected Format: (YYYY-MM-DD | YYYY-MM-DD HH:MM:SS) → YYYY-MM-DD */
    export const Format = (s?: Maybe<string>) => s ? AppDate.Format.str(Import(s)) : ''

    /** Expected Format: (YYYY-MM-DD | YYYY-MM-DD HH:MM:SS) → D. M. YYYY */
    export const Pretty = (s?: string) => s ? AppDate.Format.pretty(Import(s)) : '-'

    /** Expected Format: (YYYY-MM-DD | YYYY-MM-DD HH:MM:SS) → D. M. YYYY HH:MM:SS */
    export const PrettyFull = (s?: string) => s ? AppDate.Format.prettyFull(Import(s)) : '-'

  }

  export namespace Convert {
    export const strToStamp = (iso: string) => stamp(new Date(iso))
    export const stampToStr = (stamp: number) => AppDate.Format.str(new Date(stamp))
  }

  export namespace Util {

    export const nextDay = (d: Date) => { d.setDate(d.getDate() + 1) }

    /** Compare two dates, with Y-M-D granularity */
    export const compare = (d1: Date, d2: Date) => d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()

    export const getDayDiff = (pimDateFrom: string, pimDateTo: string) => {
      // subtract milliseconds, divide by millis per day. +1 because same dates gives 0
      return ((new Date(pimDateTo).getTime() - new Date(pimDateFrom).getTime()) / 86400000) + 1
    }

    /** get day number from 0 (monday) to 6 (sunday) */
    export const getWeekDayNorm = (d: Date) => d.getDay() === 0 ? 7 : d.getDay() - 1

    export const getWeekDayPretty = (d: Date) => {
      switch (d.getDay()) {
        case 0: return 'Ne'
        case 1: return 'Po'
        case 2: return 'Út'
        case 3: return 'St'
        case 4: return 'Čt'
        case 5: return 'Pá'
        case 6: return 'So'
        default: return ''
      }
    }

    export const getFirstDateOfWeek = (paramDate: Date): Date => {
      const d = new Date(paramDate)
      const numberOfDaysToSubtract = (d.getDay() || 7) - 1 // sunday = 0, monday = 1, ...
      d.setDate(d.getDate() - numberOfDaysToSubtract)
      return d
    }

    export const getLastDateOfWeek = (paramDate: Date): Date => {
      const d = new Date(paramDate)
      const numberDaysToAdd = 7 - (d.getDay() || 7) // add 7 because sunday is last. add 6 because monday is 1, ...
      d.setDate(d.getDate() + numberDaysToAdd)
      return d
    }

    export const getFirstDateOfMonth = (paramDate: Date): Date => {
      const d = new Date(paramDate)
      d.setDate(1) // simply set day to 1
      return d
    }
    export const getLastDateOfMonth = (paramDate: Date): Date => {
      const d = new Date(paramDate)
      d.setMonth(d.getMonth() + 1) // go to next month
      d.setDate(1) // go to first day of next month
      d.setDate(d.getDate() - 1) // go day back to last day of the month
      return d
    }

    export const getFirstDateOfYear = (paramDate: Date): Date => {
      const d = new Date(paramDate)
      d.setDate(1) // go to first day
      d.setMonth(0) // go to first month
      return d
    }
    export const getLastDateOfYear = (paramDate: Date): Date => {
      const d = new Date(paramDate)

      // may be done more easy but whatever

      d.setFullYear(d.getFullYear() + 1) // go to next year
      d.setMonth(0) // go to first month
      d.setDate(1) // go to firt day
      d.setDate(d.getDate() - 1) // go day back for last day of the year
      return d
    }

  }
}
