import gql from 'graphql-tag'

export default gql`
mutation CreateAssetByPath($filename: String!, $parentId: Int!, $type: String!, $data: String!) {
  createAsset(
    parentId: $parentId,
    type: $type,
    filename: $filename,
    input: {
      filename: $filename,
      data: $data
    }
  ) {
    success
    message
    assetData {
      id, filename, fullpath, type, mimetype, filesize
    }
  }
}
`
