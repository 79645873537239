import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { VueGQLType } from '@/common/custom/vue-apollo-customization'
import { CreateInvoicingDataResult, UpdateInvoicingDataInput, UpdateInvoicingDataResult } from '../schema/app.graphql.types'
import { InvoicingData } from '@/models/order/InvoicingData'
import { PimcoreNodeStrip, PimcoreNodeImport } from '@/models/pimcore/PimcoreNode'
import { CreateProcedure, UpdateProcedure } from '@/api/graphql/schema/generic.types'
import { ulog } from '@/common/utils/AppConsole'


export namespace MutateInvoicingData {

  export namespace Create {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation InvoicingDataCreate($key: String!, $parentId: Int!, $values: UpdateInvoicingDataInput) {
        createInvoicingData(key: $key, parentId: $parentId, published: true, input: $values) {
          success, output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      parentId: number
      key: string
      values: UpdateInvoicingDataInput
    }

    export type Data = {
      createInvoicingData: CreateInvoicingDataResult
    }

    export const keyGen = (parentKey: string) => `${parentKey}-fakturace`

    export const procedure: CreateProcedure<InvoicingData> = (key, parentId, data) => appClient
      .mutate<Data, Variables>({ mutation, variables: { key, parentId, values: data } })
      .then(r => r?.data?.createInvoicingData?.success && r.data.createInvoicingData.output
        ? PimcoreNodeImport(r.data.createInvoicingData.output)
        : ulog('e', 'MutateInvoicingData(C) failed', r)
      )
      .catch(e => ulog('e', 'MutateInvoicingData(C) exception', e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


  export namespace Update {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation InvoicingDataUpdate($fullpath: String!, $values: UpdateInvoicingDataInput!) {
        updateInvoicingData(fullpath: $fullpath, input: $values) {
          success, output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      fullpath: string
      values: UpdateInvoicingDataInput
    }

    export type Data = {
      updateInvoicingData: UpdateInvoicingDataResult
    }

    export const procedure: UpdateProcedure<InvoicingData> = data => appClient
      .mutate<Data, Variables>({
        mutation,
        variables: {
          fullpath: data.fullpath!,
          values: PimcoreNodeStrip(data),
        },
      })
      .then(r => r.data?.updateInvoicingData.success && r.data.updateInvoicingData.output
        ? PimcoreNodeImport(r.data.updateInvoicingData.output)
        : ulog('e', 'MutateInvoicingData(U) failed', r)
      )
      .catch(e => ulog('e', 'MutateInvoicingData(U) exception', e))

  }

}
