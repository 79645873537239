import { rlog } from './AppConsole'
import axios, { AxiosError, AxiosRequestHeaders } from 'axios'
import { endpoints } from '../constants/endpoints'
import { SymfonyError } from '@/api/interfaces/symfony.error.interface'


const createHeaders = (token?: string): AxiosRequestHeaders => token
  ? ({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`,
    })
  : ({
      'Content-Type': 'application/json',
    })


export const APIGet = async <T>(url: string, token?: string) => axios
  .get<T | SymfonyError>(url, { headers: createHeaders(token) })
  .then(r => r.data)
  .catch((e: AxiosError<T>) => rlog('e', e.response!.data, 'APIGet exception', e))


export const APIPost = async <T>(url: string, payload: any, token?: string) => axios
  .post<T | SymfonyError>(url, JSON.stringify(payload), { headers: createHeaders(token) })
  .then(r => r.data)


export const APIDelete = async <T>(url: string, token?: string) => axios
  .delete<T | SymfonyError>(url, { headers: createHeaders(token) })


export const isAPIError = (o: any): o is SymfonyError => {
  const assume = o as SymfonyError
  return assume.status > 0 && assume.title != null
}



/**
 * @param fp fulpath
 * @returns full URL path to the backend asset
 */
export const GetAssetURL = (fp?: string) => fp ? (fp.includes('http') ? fp : `${endpoints.main}${fp}`) : ''
