/*
 * ...
 */

import { RequirementEnum, Requirement } from '@/common/logic/auth/roles.enum'
import { RTAdmin } from './routepath/admin'
import { RTBranch } from './routepath/branch'
import { RTTopLevel } from './routepath/toplevel'
import { RTWorker } from './routepath/worker'
import { Route } from 'vue-router'


export type RouterPushQuery = { [key: string]: string | (string | null)[] | null | undefined }
export type TypedNavigateFunc = <T extends RTUnion = RTUnion>(r: T, query?: RouterPushQuery) => Promise<Route>


/** Base Route Data object definition of end-level route */
export type RouteEntry =  {
  /** unique id for this route */
  id: RTUnion
  /** display name of the route */
  name: string
  /** route path after the domain (e.g. "/admin/objednavky") */
  path: string
  /** The required authorisation to visit this route. */
  authRequirement: Requirement[]
  /** Cut routerpath for simplification (e.g. "/admin/objednavky" → "objednavky") */
  shortPath: string
  /** Additional data for possible icon. Mostly undefined */
  icon?: string

  // @future?
  // /** Additional data for possible default query. Mostly undefined */
  // defaultQuery?: RouterPushQuery
}

/** Route Entry Constructor */

/**
 * * RouteEntry Generator
 *
 * @param authIndex Authorization level of given route (A=Admin, W=Worker, a=any, n=none)
 * @param id unique identifier of the route
 * @param name display name of the route
 * @param path full route path
 * @param shortPath shortened route path
 * @returns the route entry
 */
export const RE = (authIndex: 'A'|'W'|'B'|'a'|'n', id: RTUnion, name: string, path: string, shortPath?: string): RouteEntry => ({
  authRequirement: {
    A: [RequirementEnum.ht_admin, RequirementEnum.ht_billing],
    W: [RequirementEnum.worker],
    B: [RequirementEnum.branch_montage_ht, RequirementEnum.branch_montage_branch],
    a: [RequirementEnum.any],
    n: [RequirementEnum.none],
  }[authIndex],
  id,
  name,
  path,
  shortPath: shortPath || path.substring(path.lastIndexOf('/') + 1),
})

export const withIcon = (re: RouteEntry, icon: string): RouteEntry => {
  re.icon = icon
  return re
}

// @future?
// export const withQuery = (re: RouteEntry, query: RouterPushQuery) => {
//   re.defaultQuery = query
//   return re
// }

/**
 * * T = Toplevel
 * * A = Admin
 * * B = Branch
 * * W = Worker
 */
export type RT = 'T' | 'A' | 'B' | 'W'

export type RTUnion = RTTopLevel | RTAdmin | RTWorker | RTBranch

// type SectionRoutes<T extends string> = Partial<Record<T, RouteName>>
export type FinishedSectionRoutes<T extends string> = Record<T, RouteEntry>

export type AdminRoutes = FinishedSectionRoutes<RTAdmin>
export type WorkerRoutes = FinishedSectionRoutes<RTWorker>
export type BranchRoutes = FinishedSectionRoutes<RTBranch>
export type GeneralRoutes = Record<RTTopLevel, RouteEntry>

export type AllRoutes = Record<RTTopLevel | RTAdmin | RTWorker | RTBranch, RouteEntry>

export type GenericRouteNamesType = Record<string, RouteEntry>
