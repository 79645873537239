import gql from 'graphql-tag'


export default gql`
mutation WorkerDelete($fullpath: String!) {
  deleteWorker(fullpath: $fullpath) {
    success, message
  }
}
`
