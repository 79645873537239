import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { UpdateContactDataInput, CreateContactDataResult, UpdateContactDataResult } from '../schema/app.graphql.types'
import { ContactData } from '@/models/order/ContactData'
import { CreateProcedure, UpdateProcedure } from '@/api/graphql/schema/generic.types'
import { ulog } from '@/common/utils/AppConsole'
import { PimcoreNodeImport, PimcoreNodeStrip } from '@/models/pimcore/PimcoreNode'
import { VueGQLType } from '@/common/custom/vue-apollo-customization'


export namespace MutateContactData {

  export namespace Create {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation ContactDataCreate($parentId: Int!, $key: String!, $values: UpdateContactDataInput) {
        createContactData(parentId: $parentId, key: $key, published: true, input: $values) {
          success, output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      parentId: number
      key: string
      values: UpdateContactDataInput
    }

    export type Data = {
      createContactData: CreateContactDataResult
    }

    export const keyGen = (parentKey: string) => `${parentKey}-kontakt`

    export const procedure: CreateProcedure<ContactData> = (key, parentId, data) => appClient
      .mutate<Data, Variables>({ mutation, variables: { key, parentId, values: data } })
      .then(r => r?.data?.createContactData?.success && r.data.createContactData.output
        ? PimcoreNodeImport(r.data.createContactData.output)
        : ulog('e', 'MutateContactData(C) failed', r)
      )
      .catch(e => ulog('e', 'MutateContactData(C) exception', e))


  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Update {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation ContactDataUpdate($fullpath: String!, $values: UpdateContactDataInput) {
        updateContactData(fullpath: $fullpath, input: $values) {
          success, output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      fullpath: string
      values: UpdateContactDataInput
    }

    export type Data = {
      updateContactData: UpdateContactDataResult
    }

    export const procedure: UpdateProcedure<ContactData> = async data => appClient
      .mutate<Data, Variables>({
        mutation,
        variables: {
          fullpath: data.fullpath!,
          values: PimcoreNodeStrip<ContactData>(data),
        },
      })
      .then(r => r.data?.updateContactData.success && r.data.updateContactData.output
        ? PimcoreNodeImport(r.data.updateContactData.output)
        : ulog('e', 'MutateContactData(U) failed', r)
      )
      .catch(e => ulog('e', 'MutateContactData(U) exception', e))

  }

}
