import { GQLAsset } from '@/api/graphql/app/GQLAsset'
import { GQLAssetFolder } from '@/api/graphql/app/GQLAssetFolder'
import { AssetsAPI } from '@/api/interfaces/database/assets.api.interface'


export const IAssetsAPI: AssetsAPI = {

  upload: async (variables) => {
    switch (await GQLAsset.Read.execute({ fullpath: `${variables.path}/${variables.filename}` })) {
      case 'NONEXISTENT': return await GQLAsset.Create.execute(variables)
      default: return 'FILE_EXISTS'
    }
  },

  createFolder: GQLAssetFolder.Create.procedure,
  delete: GQLAsset.Delete.execute,
  read: GQLAsset.Read.execute,
}
