// ...
/* eslint-disable */

type ConsoleSeverity = 'i' | 'w' | 'e'
/** Perform console.log and return a value */
export const rlog = <T>(severity: ConsoleSeverity, retVal: T, ...data: any[]) => {
  switch (severity) {
    case 'i': {
      clog(data)
      break
    }
    case 'w': {
      clog(data, 'w')
      break
    }
    case 'e': {
      clog(data, 'e')
      break
    }
  }
  return retVal
}

/** Perform console.log and return undefined */
export const ulog = (severity: ConsoleSeverity, ...data: any[]) => {
  return rlog(severity, undefined, data) as undefined
}

const isProduction = process.env.NODE_ENV === 'production'

export const clog = (data?, type?) => {
  if (!isProduction) {
    if (type === 'e') {
      return console.error(data)
    }
    else if (type === 'w') {
      return console.warn(data)
    }
    else {
      return console.log(data)
    }
  }
}
