import { IAuthAPI } from './implementation/auth/auth.impl'
import { IAssetsAPI } from './implementation/database/assets.impl'
import { IConfigAPI } from './implementation/database/config.impl'
import { IOrdersAPI } from './implementation/database/orders.impl'
import { IUsersAPI } from './implementation/database/users.impl'
import { IWorkflowAPI } from './implementation/database/workflow.impl'
import { IPublicAPI } from './implementation/public/public.impl'
import { IConfiguratorAPI } from './implementation/static/conf.impl'
import { AppAPI } from './interfaces/api.interface'
import { IBranchesAPI } from './implementation/database/branches.impl'
import { IWorkersAPI } from './implementation/database/workers.impl'
import { aresSearch } from './implementation/static/ares.impl'
import { getAssemblyCalendar } from './implementation/database/assemblycalendar.impl'

/** Main API Reference. */
export const API: AppAPI = {

  auth: IAuthAPI,

  static: {
    configurator: IConfiguratorAPI,
    ares: aresSearch,
  },

  db: {
    assets: IAssetsAPI,
    orders: IOrdersAPI,
    config: IConfigAPI,
    workflow: IWorkflowAPI,
    users: IUsersAPI,
    branches: IBranchesAPI,
    workers: IWorkersAPI,
    assemblyCalendar: getAssemblyCalendar,
  },

  public: IPublicAPI,

}
