import { getState } from '@/store/store.getters'

export enum JWT_VALUE {
  NO_JWT = 'NO_JWT',
}

export const JwtProvider = {
  getToken(): string {
    const state = getState.auth()
    if (!state.jwt.token) {
      return JWT_VALUE.NO_JWT
    }

    return state.jwt.token
  },
}
