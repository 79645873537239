import { Asset, Maybe } from '@/api/graphql/schema/app.graphql.types'
import { get } from '@/common/custom/ts-customization'
import { PimcoreNode } from './PimcoreNode'

/**
 * Pimcore Asset type means the metadata field in the asset. Differs from blobtype.
 * * URL https://pimcore.com/docs/pimcore/current/Development_Documentation/Assets/index.html
 */
export type PimcoreAssetType =
 | 'image'     // All image formats (jpg, png, gif, bmp)
 | 'video'     // All video formats (mp4, etc.)
 | 'document'  // All generic documents (office docs, pdf, etc)
 | 'text'      // Plaintext files
 | 'folder'    // Pimcore folder type
 | 'unknown'   // Files like EXE and other binaries

export type PimcoreAsset = Omit<PimcoreNode, 'key'> & {
  id?: number
  filename?: string
  type?: PimcoreAssetType
  mimetype?: string
  filesize?: number
  data?: string
}

export const PimcoreAsset = (o?: Maybe<Asset>): PimcoreAsset => ({
  id: get(o?.id, undefined),
  fullpath: get(o?.fullpath, undefined),

  filename: get(o?.filename, undefined),
  type: get<PimcoreAssetType>(o?.type as any, 'unknown'),
  mimetype: get(o?.mimetype, undefined),
  filesize: get(o?.filesize, undefined),
  data: get(o?.data, undefined),
})
