import gql from 'graphql-tag'

export default gql`
mutation CreateAssetByPath($filename: String!, $path: String!, $type: String!, $data: String!) {
  createAsset(
    path: $path,
    type: $type,
    filename: $filename,
    input: {
      filename: $filename,
      data: $data
    }
  ) {
    success
    message
    assetData {
      id, filename, fullpath, type, mimetype, filesize
    }
  }
}
`
