import Vue from 'vue'
// https://stackoverflow.com/questions/53588585/how-to-import-vuetify-lib-while-using-vuetify-loader-in-a-la-carte-system
// import Vuetify from 'vuetify'
// import Vuetify from 'vuetify/lib'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/src/styles/main.sass'
import '@mdi/font/css/materialdesignicons.css'
import { UserVuetifyPreset } from 'vuetify/types/services/presets'

Vue.use(Vuetify)

// https://vuetifyjs.com/en/features/presets/#default-preset
export const vuetifyPreset: Partial<UserVuetifyPreset> = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0066ff',
      },
      dark: {},
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
}

export default new Vuetify(vuetifyPreset)
