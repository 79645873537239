import { Asset, Object_OrderDoorConfiguration_ProtocolData, Object_ProtocolData, UpdateProtocolDataInput } from '@/api/graphql/schema/app.graphql.types'
import { Maybe } from '@/api/graphql/schema/generic.types'
import { ObjectParams, get } from '@/common/custom/ts-customization'
import { PimcoreAsset } from '@/models/pimcore/PimcoreAsset'
import { PimcoreNode } from '@/models/pimcore/PimcoreNode'
import { PimcoreRelation } from '@/models/pimcore/PimcoreRelation'

export type ProtocolData = PimcoreNode & {

  /** Výrobní číslo dveří */
  serialNumber: string

  /** Výška kukátka */
  peepholeHeight: string

  /** Výška dveří */
  doorHeight: string

  /** Potvrdenia - JSON bool array (s konkretnym poradim) */
  confirmations: boolean[] // JSON

  /** Poznámky zakaznika */
  clientNotes: string

  /** Datum a cas vytvorenie protokolu */
  protocolCreation: string

  /** Odkaz na vygenerovany pdf asset */
  protocol: PimcoreAsset

  /** Odkaz na vygenerovany pdf asset */
  installationProtocol: PimcoreAsset

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  /** Pověřená osoba (Niekto nový alebo kópia kontaktu) */
  authorizedPerson: string

  /** Občanský průkaz */
  authorizedPersonID: string

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  signatureClient: PimcoreAsset
  signatureWorker: PimcoreAsset

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  isMagnum: boolean
  isOperable: boolean
  workerNotes: string
  documentsFolderId: number
  assemblyPictures: PimcoreAsset[]
  manualUpload: boolean
}
export const ProtocolData = (o?: Maybe<Object_ProtocolData>): ProtocolData => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),

  serialNumber: o?.serialNumber || '',
  peepholeHeight: o?.peepholeHeight || '',
  doorHeight: o?.doorHeight ||  '',
  confirmations: o?.confirmations ? JSON.parse(o.confirmations) : [],
  clientNotes: o?.clientNotes || '',
  protocolCreation: o?.protocolCreation || '',
  protocol: PimcoreAsset(o?.protocol),
  authorizedPerson: o?.authorizedPerson || '',
  authorizedPersonID: o?.authorizedPersonID || '',
  signatureClient: PimcoreAsset(o?.signatureClient),
  signatureWorker: PimcoreAsset(o?.signatureWorker),
  installationProtocol: PimcoreAsset(o?.installationProtocol),
  isMagnum: o?.isMagnum || false,
  isOperable: o?.isOperable || false,
  workerNotes: o?.workerNotes ||  '',
  documentsFolderId: o?.documentsFolderId || 0,
  assemblyPictures: [],
  manualUpload: o?.manualUpload || false,
})


export const ProtocolDataImport = (o?: Maybe<Array<Maybe<Object_OrderDoorConfiguration_ProtocolData>>>) => {
  const imported: ProtocolData[] = []
  if (o) {
    for (const item of o) {
      const n = ProtocolData()
      if (item) {
        for (const k of Object.keys(item) as Array<keyof ProtocolData>) {
          switch (k) {
            case 'assemblyPictures':        n[k] = get<Maybe<Asset>[]>(item.assemblyPictures, []).map(a => PimcoreAsset(a)); break
            default:                        if (item[k]) (n[k] as any) = item[k]
          }
        }
      }
      imported.push(n)
    }
  }
  return imported
}

export const ProtocolDataExport = (o: ProtocolData) => {
  const exported: UpdateProtocolDataInput = {
    documentsFolderId: null,
    assemblyPictures: null,
  }

  for (const k of ObjectParams(o)) {
    switch (k) {
      case 'confirmations':   exported[k] = JSON.stringify(o.confirmations); break
      case 'signatureClient': exported[k] = PimcoreRelation('asset', o.signatureClient); break
      case 'signatureWorker': exported[k] = PimcoreRelation('asset', o.signatureWorker); break
      case 'installationProtocol': exported[k] = PimcoreRelation('asset', o.installationProtocol); break
      case 'protocol': exported[k] = PimcoreRelation('asset', o.protocol); break
      case 'assemblyPictures':  exported[k] = o.assemblyPictures.map(a => PimcoreRelation('asset', a)!); break

      default: (exported[k] as any) = o[k]
    }

    if (!exported[k] && typeof exported[k] !== 'boolean') delete exported[k]
  }

  return exported
}


export const ProtocolDataExportAdditionalPictures = (o: ProtocolData) => {
  const exported: UpdateProtocolDataInput = {
    documentsFolderId: null,
    assemblyPictures: null,
  }

  for (const k of ObjectParams(o)) {
    switch (k) {
      case 'assemblyPictures':  exported[k] = o.assemblyPictures.map(a => PimcoreRelation('asset', a)!); break
    }
    if (!exported[k] && typeof exported[k] !== 'boolean') delete exported[k]
  }

  return exported
}
