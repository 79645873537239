import store from './root.store'
import { AssemblyCalendarEvent } from '@/common/componentHelpers/AssemblyCalendar'
import { AuthState } from './auth.store'
import { JWT, User } from '@/common/logic/auth/auth.types'
import { Configuration } from '@/models/order/Configuration'
import { RouterPushQuery, RTUnion } from '@/router/interface'


export const storeAction = {

  initApp: () => store.dispatch('initialize'),

  appData: {
    cacheRedir: (payload: { route: RTUnion, query: RouterPushQuery }) => store.dispatch('appData/cacheRedir', payload),
  },

  calendar: {
    reset: () => store.dispatch('calendar/reset'),

    openDetailDialog: (entry: AssemblyCalendarEvent) => store.dispatch('calendar/openDetailDialog', entry),
    openEditDialog: () => store.dispatch('calendar/openEditDialog'),

    close: () => store.dispatch('calendar/closeCalendar'),
    openForEdit: (config: Configuration, index: number) => store.dispatch('calendar/openCalendarForEdit', { config, index }),

    setEditedCell: (config: Configuration) => store.dispatch('calendar/setEditedCellData', config),
  },

  auth: {
    setState: (state: AuthState) => store.dispatch('auth/setState', state),

    jwt: {
      set: (jwt: JWT) => store.dispatch('auth/setJWT', jwt),
      clear: () => store.dispatch('auth/clearJWT'),
    },

    user: {
      set: (user: User) => store.dispatch('auth/setUser', user),
      clear: () => store.dispatch('auth/clearUser'),
    },

  },

}
