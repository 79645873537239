import { Object_Worker, Maybe, UpdateWorkerInput } from '@/api/graphql/schema/app.graphql.types'
import { ObjectParams } from '@/common/custom/ts-customization'
import { PimcoreNode } from '../pimcore/PimcoreNode'

export type Worker = PimcoreNode & {
  firstName: string
  lastName: string
  phone: string
  email: string
}
export const Worker = (o?: Maybe<Object_Worker>): Worker => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),
  firstName: o?.firstName || '',
  lastName: o?.lastName || '',
  phone: o?.phone || '',
  email: o?.email || '',
})

export const WorkerExport = (o: Worker): UpdateWorkerInput => {
  const exported: UpdateWorkerInput = {}
  for (const k of ObjectParams(o)) {
    (exported[k] as any) = o[k]
    if (!exported[k] && typeof exported[k] !== 'boolean') delete exported[k]
  }
  return exported
}
