import { EventTimespan, eventTimespanPrint } from './AssemblyCalendar'
import { FormSelection } from '../custom/ts-customization'
import { Configuration } from '@/models/order/Configuration'
import { Order } from '@/models/order/Order'
import moment from 'moment'

export const readInstallDate = (conf?: Configuration, order?: Order): string => {
  const morningAssemblyTime = order?.branchOffice.morningAssemblyTime || null
  const afternoonAssemblyTime = order?.branchOffice.afternoonAssemblyTime || null
  const eveningAssemblyTime = order?.branchOffice.eveningAssemblyTime || null

  const configurationTimeMorning = conf?.installationMorning || false
  const configurationTimeAfternoon = conf?.installationAfternoon || false
  const configurationTimeEvening = conf?.installationEvening || false

  if (configurationTimeMorning && morningAssemblyTime) {
    return `${moment(conf?.installationDate).format('D. M. YYYY')} ${morningAssemblyTime}`
  }

  if (configurationTimeAfternoon && afternoonAssemblyTime) {
    return `${moment(conf?.installationDate).format('D. M. YYYY')} ${afternoonAssemblyTime}`
  }

  if (configurationTimeEvening && eveningAssemblyTime) {
    return `${moment(conf?.installationDate).format('D. M. YYYY')} ${eveningAssemblyTime}`
  }

  if (conf?.installationDate) {
    return `${moment(conf?.installationDate).format('D. M. YYYY')} ${eventTimespanPrint(EventTimespan(configurationTimeMorning, configurationTimeAfternoon, configurationTimeEvening))}`
  }

  return ''
}


export type InputSelectItem = { label: string; value: any }
export const InputSelectItems = (formSelection: FormSelection<any>): InputSelectItem[] => {
  return Object.keys(formSelection).map(item => {
    return {
      label: formSelection[item].label,
      value: formSelection[item].key,
    }
  })
}


export const priceWithVat = (price: string) => parseInt(price).toLocaleString('cs')

export const basePriceTitle = (directSell: boolean) => directSell ? 'Cena dveří bez montáže' : 'Cena dveří s montáží'
