import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { UsersAPI } from '@/api/interfaces/database/users.api.interface'
import { endpoints } from '@/common/constants/endpoints'
import { ObjectParams } from '@/common/custom/ts-customization'
import { CreateUser } from '@/common/logic/auth/auth.types'
import { APIDelete, APIGet, APIPost, isAPIError } from '@/common/utils/REST'
import { PimcoreUserRequest } from '@/models/pimcore/restapi/PimcoreUserRequest'
import { PimcoreUserResponse } from '@/models/pimcore/restapi/PimcoreUserResponse' // isPimcoreUserResponse, isPimcoreUserResponseArr
import { getState } from '@/store/store.getters'
import { JwtProvider } from '@/api/implementation/auth/jwtProvider'
import { AxiosResponse } from 'axios'

const UserApiErrorEnum = {
  UNKNOWN: 'UNKNOWN',
  NO_JWT: 'NO_JWT',
  EXISTS: 'EXISTS',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
}
export type UserApiError = keyof typeof UserApiErrorEnum
export const isUserApiError = (o: any): o is UserApiError =>
  typeof o === 'string' && ObjectParams(UserApiErrorEnum).includes(o as any)

export const IUsersAPI: UsersAPI = {
  async list() {
    const state = getState.auth()
    if (!state.jwt.token) return 'NO_JWT'

    const r = await APIGet<PimcoreUserResponse[]>(endpoints.auth.user_list, state.jwt.token)
    return isAPIError(r) ? 'UNKNOWN' : Promise.all(r.map(i => CreateUser(i))) // r.map(i => User(i)) // await Promise.all(r.map(i => CreateUser(i)))
  },

  async register(user, initialPassword) {
    const state = getState.auth()
    const newUser = PimcoreUserRequest(user, initialPassword)
    if (!state.jwt.token) return 'NO_JWT'
    const r = await APIPost<PimcoreUserResponse>(endpoints.auth.register, newUser, state.jwt.token)

    if (!r) {
      return 'UNKNOWN'
    }

    if (isAPIError(r)) {
      if (/exists/.test(r.detail)) {
        return 'EXISTS'
      }
      return 'UNKNOWN'
    }
    return CreateUser(r)
  },

  async edit(user) {
    const state = getState.auth()
    const updatedUser = PimcoreUserRequest(user)
    if (!state.jwt.token) return 'NO_JWT'
    const r = await APIPost<PimcoreUserResponse>(
      endpoints.auth.user_edit,
      updatedUser,
      state.jwt.token
    )
    return isAPIError(r) ? 'UNKNOWN' : CreateUser(r)
  },

  async passwordUpdate(user, password) {
    const updatedUser = PimcoreUserRequest(user)
    const email = updatedUser.email
    const USER_PASSWORD_UPDATE_MUTATION = gql`
      mutation UserPasswordChangeByAdmin($email: String!, $password: String!) {
        userPasswordChangeByAdmin(email: $email, password: $password) {
          email
        }
      }
    `

    try {
      const response = await appClient.mutate({
        mutation: USER_PASSWORD_UPDATE_MUTATION,
        variables: { email, password },
      })

      if (response.data && response.data.userPasswordChangeByAdmin && response.data.userPasswordChangeByAdmin.email) {
        return response.data.userPasswordChangeByAdmin
      }

      else {
        return 'UNKNOWN'
      }
    }
    catch (e: any) {
      return 'UNKNOWN'
    }
  },

  async delete(id: number): Promise<AxiosResponse> {
    return await APIDelete(
      endpoints.auth.user_delete.replace(':id', id.toString()),
      JwtProvider.getToken()
    )
  },
}
