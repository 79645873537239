import { Maybe, Geopoint } from '@/api/graphql/schema/app.graphql.types'


export type PimcoreGeopoint = {
  latitude: number
  longitude: number
}

export const PimcoreGeopoint = (o?: Maybe<Geopoint>): PimcoreGeopoint => ({
  latitude: o?.latitude || 0,
  longitude: o?.longitude || 0,
})
