import gql from 'graphql-tag'


export default gql`
query GetWorkerOccupancy(
  $from: String
  $to: String
  $branchOfficeId: Int
  $workerGroupId: Int
) {
  getWorkerOccupancy(
    from: $from,
    to: $to,
    branchOfficeId: $branchOfficeId,
    workerGroupId: $workerGroupId
  ) {

    from, to, branchOfficeId, workerGroupId, totalCount,

    edges {

      workerGroup {
        id, key, fullpath, workerGroupID, color, availableMorning, availableAfternoon, availableEvening
      }

      vacations {
        id, fullpath, key, dateFrom, dateTo, isMorning, isAfternoon, isEvening, isFullday
      }

      blockings {
        id, fullpath, key, dateFrom, dateTo, isMorning, isAfternoon, isEvening, isFullday, description
      }

    }

  }
}
`
