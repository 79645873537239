//
// * @Andrej
// Custom-defined definition file.
// Potencialne uzitocne genericke datove typy.
// Pouzivanie len podla preferencii programatora.
//
// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

import { Maybe } from '@/api/graphql/schema/generic.types'

export type Nullable<T> = T | undefined

export type OptionMapOf<T> = {
  [P in keyof T]: Array<T[P]>
}

/** Typed version of Object.keys */
export const ObjectParams = <T extends object>(o: T) => Object.keys(o) as Array<keyof T>

/**
 * * Removes the possibility to contain NULL value. Extract the value, return a default value if null present.
 * * The default value may be undefined as well.
 */
export const get = <T>(param: Nullable<Maybe<any>>, defaultValue: T): T => {
  return param as T || defaultValue
}

// export const unboxTry = <T>(param: Nullable<Maybe<T>>, defaultValue?: T): Nullable<T> => param || defaultValue

// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

export type FormSelectionItem<T> = {
  key: T
  label: string
}

/** Helper typing for most dropdowns/checkboxes/radiobuttons */
export type FormSelection<T extends string> = Record<T, FormSelectionItem<T>>

// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object

/** Recursion depth limitation for type checking */
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

type Innermost <K, P> = K extends string
  ? P extends string
    ? '' extends P ? K : P
    : never
  : never

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : ''

export type LeavesInnermost<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Innermost<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : ''
