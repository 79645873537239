import gql from 'graphql-tag'

export default gql`
query OrderList($first: Int! = 1, $after: Int! = 0, $sortBy: [String]! = ["orderNumber"], $sortOrder: [String]! = ["DESC"], $filter: String = null) {
  getOrderListing(first: $first, after: $after, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter) {
    totalCount
    edges {
      node {
        id, key, fullpath,
        isPriority, isEmergency, orderNumber, state,

        invoicingData {
          ... on object_InvoicingData {
            id, key, fullpath, invoicedEntity, firstName, lastName, companyName, city
          }
        }
        configurations {
          ... on object_OrderDoorConfiguration {
            id, key, fullpath,
            parameters, installationDate, installationMorning, installationAfternoon
            workerGroup {
              ... on object_WorkerGroup {
                id, key, fullpath, workerGroupID
              }
            }
          }
        }
        branchOffice {
          ... on object_BranchOffice {
            id, key, fullpath, name
          }
        }
      }
    }
  }
}
`
