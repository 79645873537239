//
// Application initialization module
// This implementation is run on app init => always when refreshing page
//

import { API } from './api'
import { storeAction } from './store/store.actions'


export const appInit = async () => {
  // 1. Vuex Store initialization

  // init root store
  await storeAction.initApp()

  // init auth store (performs localstorage search && API call)
  await API.auth.initialize()
}

export const appConfiguration = {
  backend: {
    get: () => process.env.VUE_APP_PROD === 'true' ? 'PROD' : 'LOCAL',
    setLocal: () => globalThis.window.localStorage.setItem('useLocalDev', 'LOCAL'),
    setGlobal: () => globalThis.window.localStorage.removeItem('useLocalDev'),
  },

}
