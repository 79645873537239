import { GQLConfigActions } from '@/api/graphql/app/GQLConfigActions'
import { GQLOrderActions } from '@/api/graphql/app/GQLOrderActions'
import { WorkflowAPI } from '@/api/interfaces/database/workflow.api.interface'


export const IWorkflowAPI: WorkflowAPI = {

  getOrderActions: GQLOrderActions.Get.procedure,
  applyOrderAction: GQLOrderActions.Apply.procedure,
  getOrderActionsPreview: GQLOrderActions.GetPreview.procedure,
  applyOrderActionPreview: GQLOrderActions.ApplyPreview.procedure,

  getConfigActions: GQLConfigActions.Get.procedure,
  applyConfigAction: GQLConfigActions.Apply.procedure,
  applyConfigActionError: GQLConfigActions.isError,

}
