import { UserRoleGroup } from '@/common/logic/auth/roles.enum'
import { storeGetter } from '@/store/store.getters'
import { User } from '@/common/logic/auth/auth.types'

const RoutePathResolver  = {
  install: (Vue) => {
    Vue.prototype.$routePathProvider = {
      orderDetail: () => {
        switch (UserRoleGroup(storeGetter.auth<User>('user').role)) {
          case 'ht':      return 'admin_detail_objednavky'
          case 'branch':  return 'branch_detail_objednavky'
          case 'worker':  return 'worker_detail_objednavky'
          default:        return undefined
        }
      },
      openNewOrder: () =>  {
        return 'editOrCreateOrder'
      },
      serviceRequestDetail: () => {
        switch (UserRoleGroup(storeGetter.auth<User>('user').role)) {
          case 'ht':      return 'admin_detail_reklamace'
          case 'branch':  return 'branch_detail_reklamace'
          case 'worker':  return 'worker_detail_reklamace'
          default:        return undefined
        }
      },
      // openNewServiceRequest: () =>  {
      //   return 'editOrCreateServiceRequest'
      // },
    }
  },
}

export default RoutePathResolver
