import { rlog, ulog } from '@/common/utils/AppConsole'
import { AssemblyCalendarParams } from '@/models/customview/AssemblyCalendar'
import { WorkerOccupation, WorkerOccupationExport } from '@/models/workers/WorkerOccupation'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { CreateWorkerOccupancyResult, DeleteWorkerOccupationResult, UpdateWorkerOccupationInput, UpdateWorkerOccupationResult, WorkerOccupancy } from '../../schema/app.graphql.types'
import createWorkerOccupancy from './createWorkerOccupancy'
import getWorkerOccupancy from './getWorkerOccupancy'
import deleteWorkerOccupancy from './deleteWorkerOccupancy'
import updateWorkerOccupation from './updateWorkerOccupancy'

export namespace GQLWorkerOccupancy {

  export namespace Get {

    const query = getWorkerOccupancy

    export type Variables = AssemblyCalendarParams

    export type Data = {
      getWorkerOccupancy: WorkerOccupancy
    }

    export const execute = async (variables: Variables) => appClient
      .query<Data, Variables>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getWorkerOccupancy.edges != null
        ? r.data.getWorkerOccupancy.edges
        : ulog('e', 'GQLWorkerOccupancy(R) failed', r)
      )
      .catch(e => ulog('e', 'GQLAssemblyCalendar(R) exception', e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Create {
    const mutation = createWorkerOccupancy

    export type Variables = {
      entryType: string
      workerGroupId: number
      dateFrom: string
      dateTo: string
      beginFromMorning: boolean
      beginFromAfternoon: boolean
      beginFromEvening: boolean
      blockingDescription: string
    }

    export type Data = {
      createWorkerOccupancy: CreateWorkerOccupancyResult
    }

    const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.createWorkerOccupancy.success
        ? true
        : rlog('e', false, 'GQLCreateWorkerOccupancy failed', r)
      )
      .catch(e => rlog('e', false, 'GQLCreateWorkerOccupancy exception', e))

    export const procedure = async (occupancy: WorkerOccupation) =>
      execute(WorkerOccupationExport(occupancy))
  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Delete {
    const mutation = deleteWorkerOccupancy

    export type Variables = {
      id: number
      fullpath?: string
    }

    export type Data = {
      deleteWorkerOccupancy: DeleteWorkerOccupationResult
    }

    const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.deleteWorkerOccupancy.success
        ? true
        : rlog('e', false, 'GQLCreateWorkerOccupancy failed', r)
      )
      .catch(e => rlog('e', false, 'GQLCreateWorkerOccupancy exception', e))

    export const procedure = async (occupancy: Variables) =>
      execute(occupancy)
  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Update {
    const mutation = updateWorkerOccupation

    export type Variables = {
      id: number,
      values: UpdateWorkerOccupationInput
    }

    export type Data = {
      updateWorkerOccupation: UpdateWorkerOccupationResult
    }

    const execute = async (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r.data?.updateWorkerOccupation.success && r.data.updateWorkerOccupation.output
        ? r.data.updateWorkerOccupation.output
        : ulog('e', 'GQLupdateWorkerOccupation(U) failed', r)
      )
      .catch(e => ulog('e', 'GQLupdateWorkerOccupation(U) exception', e))

    export const procedure = async (id: number, event: UpdateWorkerOccupationInput) =>
      execute({ id: id!, values: event })
  }

}
