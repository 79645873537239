// ...

export const POPUP_DISPLAY_TIME_MS = 5000

/** Auth-related constant. */
export const ACCESS_TOKEN_EXPIRATION_MS = 1000 /* ms */ * 60 /* sec */ * 15 /* min */


export const HTDVERE_MAIL = 'htdvere@htdvere.cz'
export const HTDVERE_MAIL_INVOICING = 'fakturace@htdvere.cz'
