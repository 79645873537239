import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import { re, routeEntriesArr, routeTitles } from './routeEntries'
import { RequirementEnum } from '@/common/logic/auth/roles.enum'
import { mayAccess } from '@/common/logic/auth/roles.utils'
import { User } from '@/common/logic/auth/auth.types'
import { getState, storeGetter } from '@/store/store.getters'
import { storeAction } from '@/store/store.actions'
import { RTUnion } from './interface'


type GuardFunctionParams = {
  to: Route
  from: Route
  next: NavigationGuardNext<Vue>
}

export const withGuards = (router: VueRouter): VueRouter => {
  router.beforeEach((to, from, next) => {
    const destination = routeEntriesArr.find(v => v.path === to.path)
    const userRole = storeGetter.auth<User>('user').role

    const appData = getState.appData()
    const cacheRedirEmpty = appData.cachedQuery === undefined && appData.cachedRoute === ''

    if (cacheRedirEmpty) {
      storeAction.appData.cacheRedir({ route: destination?.id || 'index', query: to.query })
    }

    // console.log(`beforeEach :: role=[${userRole}], requirement=[${destination?.authRequirement}] → [${destination?.id}]`)

    // [0] Is not found
    if (!destination) return next({ name: re.index.id })

    // [1] URL's that are valid but don't contain any content
    const nullContentRoutes: RTUnion[] = ['index', 'admin', 'montaznik', 'pobocka']
    if (nullContentRoutes.includes(destination.id)) return nullContentGuard({ to, from, next })

    // [2] ACL Check
    if (!mayAccess(userRole, destination.authRequirement)) {
      return nullContentGuard({ to, from, next })
    }

    // [3] User authorized, but app wants to load non-auth page
    const nonauthContentRoutes: RTUnion[] = ['prihlaseni', 'zapomenute_heslo', 'obnova_hesla']
    if (userRole !== 'none' && nonauthContentRoutes.includes(destination.id)) {
      if (cacheRedirEmpty) return nullContentGuard({ to, from, next })
      else return next({ name: appData.cachedRoute, query: appData.cachedQuery })
    }

    // [4] Nothing was handled, call simple next.
    next()
  })

  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  // https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
  router.afterEach(to => Vue.nextTick(() => overridePageTitle(to)))

  return router
}

/**
 * Handle landing on plain index page. Redirect depending on auth state.
 * @use store to check auth.
 * @use router + routeNames
 */
const nullContentGuard = (guard: GuardFunctionParams) => {
  // console.log(`nullContentGuard :: role=[${storeGetter.auth<User>('user').role}]`)
  switch (storeGetter.auth<User>('user').role) {
    case RequirementEnum.none:      return guard.next({ name: re.prihlaseni.id })
    case RequirementEnum.worker:    return guard.next({ name: re.worker_objednavky.id })

    case RequirementEnum.ht_admin:
    case RequirementEnum.ht_billing:
      return guard.next({ name: re.admin_objednavky.id })

    case RequirementEnum.branch_montage_ht:
    case RequirementEnum.branch_montage_branch:
      return guard.next({ name: re.branch_objednavky.id })
  }
}

const overridePageTitle = (route: Route) => {
  let pageTitle: string | undefined
  const exactRoute = routeEntriesArr.find(v => v.path === route.path)

  if (exactRoute) {
    pageTitle = `${routeTitles.prefix} | ${exactRoute.name}`

    const routesWithQuery = ['admin-order-detail', 'admin-request-detail']
    if (routesWithQuery.includes(exactRoute.id)) {
      pageTitle = `${routeTitles.prefix} | ${exactRoute.name} ${route.query.id}`
    }
  }

  // Set default title in case of emergency
  document.title = pageTitle || routeTitles.undef
}
