import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink, HttpLink } from 'apollo-link-http'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { endpointBase } from '@/common/constants/endpoints'
import { onError } from '@apollo/client/link/error'
import { IAuthAPI } from '@/api/implementation/auth/auth.impl'

// Install the vue plugin
Vue.use(VueApollo)

const url = `${endpointBase}/pimcore-graphql-webservices`

export type GQLClient = ApolloClient<NormalizedCacheObject>


const authMiddleware = new ApolloLink((operation, forward) => {
  const vuex = localStorage.getItem('vuex') as string
  const token = JSON.parse(vuex)?.auth?.jwt.token

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  return forward(operation)
})

const httpLink = new HttpLink({ uri: `${url}/app?apikey=${process.env.VUE_APP_GRAPHQL_APP_APIKEY}` })

const errorLink = onError(({ networkError }) => {
  if (networkError?.message.includes('Unexpected token')) {
    IAuthAPI.logout()
    window.location.href = '/prihlaseni'
  }
})

// Create the apollo client
export const appClient = new ApolloClient({
  link: ApolloLink.from([
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    errorLink,
    authMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
})

export const publicClient = new ApolloClient({
  link: createHttpLink({ uri: `${url}/public?apikey=${process.env.VUE_APP_GRAPHQL_PUBLIC_APIKEY}` }),
  cache: new InMemoryCache(),
})


export const apolloProvider = new VueApollo({
  defaultClient: appClient,
  clients: {
    public: publicClient,
  },
  defaultOptions: {
    $query: {
      // fetchPolicy: 'cache-and-network',
    },
  },
  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
  },
})



// // import { ApolloClient } from 'apollo-client'
// // import 'vue-cli-plugin-apollo/types'
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'



// // Name of the localStorage item
// const AUTH_TOKEN = 'apollo-token'

// // Http endpoint
// const httpEndpoint = `${process.env.VUE_APP_BASE_URL}/pimcore-graphql-webservices/orders?apikey=${process.env.VUE_APP_GRAPHQL_APIKEY}`
// // process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'

// // Files URL root
// export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

// Vue.prototype.$filesRoot = filesRoot

// // Config
// const defaultOptions = {
//   // You can use `https` for secure connection (recommended in production)
//   httpEndpoint,
//   // You can use `wss` for secure connection (recommended in production)
//   // Use `null` to disable subscriptions
//   wsEndpoint: undefined, // process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
//   // LocalStorage token
//   // tokenName: AUTH_TOKEN,
//   // Enable Automatic Query persisting with Apollo Engine
//   persisting: false,
//   // Use websockets for everything (no HTTP)
//   // You need to pass a `wsEndpoint` for this to work
//   websocketsOnly: false,
//   // Is being rendered on the server?
//   ssr: false,

//   // Override default apollo link
//   // note: don't override httpLink here, specify httpLink options in the
//   // httpLinkOptions property of defaultOptions.
//   // link: myLink

//   // Override default cache
//   // cache: myCache

//   // Override the way the Authorization header is set
//   // getAuth: (tokenName) => ...

//   // Additional ApolloClient options
//   // apollo: { ... }

//   // Client local data (see apollo-link-state)
//   // clientState: { resolvers: { ... }, defaults: { ... } }
// }

// export const appClient = ((): ApolloClient<any> => {
//   const { apolloClient, wsClient } = createApolloClient<any>({
//     ...defaultOptions,
//     // ...options,
//   })

//   ;(<any>apolloClient).wsClient = wsClient

//   return apolloClient
// })()

// // Call this in the Vue app file
// function createProvider() {
//   // options = {}

//   // Create apollo client
//   // const { apolloClient, wsClient } = createApolloClient<any>({

//   //   ...defaultOptions,
//   //   ...options,
//   // })

//   // ;(<any> apolloClient).wsClient = wsClient

//   // Create vue apollo provider
//   const apolloProvider = new VueApollo({
//     defaultClient: appClient,
//     clients: {
//       public: publicClient,
//     },
//     defaultOptions: {
//       $query: {
//         // fetchPolicy: 'cache-and-network',
//       },
//     },
//     errorHandler(error) {
//       // eslint-disable-next-line no-console
//       console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
//     },
//   })

//   return apolloProvider
// }

// // Manually call this when user log in
// export async function onLogin(apolloClient, token) {
//   if (typeof localStorage !== 'undefined' && token) {
//     localStorage.setItem(AUTH_TOKEN, token)
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   }
//   catch (e: any) {
//     // eslint-disable-next-line no-console
//     console.log('%cError on cache reset (login)', 'color: orange;', e.message)
//   }
// }

// // Manually call this when user log out
// export async function onLogout(apolloClient) {
//   if (typeof localStorage !== 'undefined') {
//     localStorage.removeItem(AUTH_TOKEN)
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
//   try {
//     await apolloClient.resetStore()
//   }
//   catch (e: any) {
//     // eslint-disable-next-line no-console
//     console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
//   }
// }
