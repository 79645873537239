import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { UpdateBranchOfficeInput, UpdateBranchOfficeResult, CreateBranchOfficeResult } from '../schema/app.graphql.types'
import { ulog } from '@/common/utils/AppConsole'
import { BranchOffice, BranchOfficeExport } from '@/models/branchoffice/BranchOffice'

export namespace MutateBranchOffice {

  export namespace Create {
    const mutation = gql`
    mutation CreateBranchOffice($key: String!, $parentId: Int!, $values: UpdateBranchOfficeInput) {
      createBranchOffice(key: $key, parentId: $parentId, input: $values) {
      success, message, output { id }
    }
  }
  `


    export type Variables = {
      key: string
      parentId: number
      values: UpdateBranchOfficeInput
    }


    export type Data = {
      createBranchOffice: CreateBranchOfficeResult
    }

    const execute = (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.createBranchOffice.success && r.data.createBranchOffice.output
        ? r.data.createBranchOffice.output
        : ulog('e', 'MutateBO(U) procedure failed:', r)
      )
      .catch(e => ulog('e', 'MutateBO(U) exception', e))

    export const procedure = (key: string, branch: BranchOffice, regionId: number) =>
      execute({ key: key, parentId: regionId, values: BranchOfficeExport(branch) })

  }

  export namespace Update {

    const mutation = gql`
      mutation BranchOfficeUpdate($id: Int!, $values: UpdateBranchOfficeInput) {
        updateBranchOffice(id: $id, input: $values) {
          success
          output { id, key, fullpath }
        }
      }
    `

    export type Variables = {
      id: number
      values: UpdateBranchOfficeInput
    }


    export type Data = {
      updateBranchOffice: UpdateBranchOfficeResult
    }

    const execute = (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.updateBranchOffice.success && r.data.updateBranchOffice.output
        ? r.data.updateBranchOffice.output
        : ulog('e', 'MutateBO(U) procedure failed:', r)
      )
      .catch(e => ulog('e', 'MutateBO(U) exception', e))

    export const procedure = (branch: BranchOffice) =>
      execute({ id: parseInt(branch.id!), values: BranchOfficeExport(branch) })

  }
}
