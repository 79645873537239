import { appClient } from '@/plugins/vue-apollo.plugin'
import { rlog, ulog } from '@/common/utils/AppConsole'
import { BranchOfficeConnection, Object_BranchOffice } from '../../schema/app.graphql.types'
import { BranchOffice } from '@/models/branchoffice/BranchOffice'
import getBranchOfficeListing from './getBranchOfficeListing'
import getBranchOffice from './getBranchOffice'
import { DefaultValues, Variables } from '@/api/graphql/app/branch/BranchOfficeListVariables'


export namespace GQLBranchOffice {

  export namespace List {

    const query = getBranchOfficeListing

    export type Data = {
      getBranchOfficeListing: BranchOfficeConnection
    }


    export const procedure = (variables: Variables = DefaultValues) => appClient
      .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getBranchOfficeListing && r.data.getBranchOfficeListing.edges
        ? r.data.getBranchOfficeListing.edges.map(i => BranchOffice(i?.node))
        : rlog<BranchOffice[]>('e', [], 'GQLBranchOffice(L) failed', r)
      )
      .catch(e => rlog<BranchOffice[]>('e', [], 'GQLBranchOffice(L) exception', e))

  }


  export namespace Get {

    const query = getBranchOffice

    export type Variables = {
      id: number
    }

    export type Data = {
      getBranchOffice: Object_BranchOffice
    }

    const execute = (variables: Variables) => appClient
      .query<Data, Variables>({ query, variables, fetchPolicy: 'no-cache' })
      .then(r => r.data.getBranchOffice ? BranchOffice(r.data.getBranchOffice) : ulog('e', 'GQLBranchOffice (R) failed', r))
      .catch(e => ulog('e', 'GQLBranchOffice (R) exception', e))

    export const procedure = async (id: number) => execute({ id })

  }

}
