import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { IAuthAPI } from '@/api/implementation/auth/auth.impl'

axios.interceptors.request.use(
  (config) => {
    const vuex = localStorage.getItem('vuex') as string
    const token = JSON.parse(vuex).auth.jwt.token


    if (token && !config.url?.includes('/configuration/')) {
      config.headers!.Authorization = `Bearer ${token}`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    IAuthAPI.logout()
    window.location.href = '/prihlaseni'
  }
  return error
})

Vue.use(VueAxios, axios)

// axios.defaults.withCredentials = true
