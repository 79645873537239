import { Object_Order, Object_OrderDoorConfiguration } from '@/api/graphql/schema/app.graphql.types'
import { readInstallDate } from '@/common/componentHelpers/ConfigSheet'
import { AppDate } from '@/common/utils/AppDate'
import { Maybe } from 'graphql/jsutils/Maybe'
import { ConfigurationImport } from '../order/Configuration'
import { InvoicingData } from '../order/InvoicingData'
import { OrderType } from '../order/Order'
import { StateInquiry, StateOrder } from '../order/workflow/States'
import { PimcoreNode } from '../pimcore/PimcoreNode'


/** Pre tabulku */
export type Summary = PimcoreNode & {
  /** typ - objednavka / poptavka */
  orderType: string
  /** Číslo objednávky */
  orderNumber: string
  /** Priorita objednávky */
  isPriority: boolean
  /** ... */
  isEmergency: boolean
  /** Jméno & Příjmení */
  entityName: string
  /** Pobočka (Ústí nad Labem) */
  branchName: string
  /** konfiguracie / montaze */
  configurations: SummaryConfiguration[]
  /** stav */
  state: StateOrder | StateInquiry

  inquiryCreated: string

}
export const Summary = (o?: Maybe<Object_Order>): Summary => ({
  orderType: (o?.orderType || 'order') as OrderType,
  orderNumber: o?.orderNumber || '',
  isPriority: o?.isPriority || false,
  isEmergency: o?.isEmergency || false,
  entityName: getEntityName(o),
  branchName: `${o?.branchOffice?.name}` || '-',
  configurations: getOrderSummaryConfigurations(o),
  state: (o?.state || 'orderNew') as StateOrder | StateInquiry,
  inquiryCreated: o?.creationDateInquiry || '-',
})


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


export type SummaryConfiguration = PimcoreNode & {
  /** Obec */
  city: string
  /** Specifikace (C / P / zlatý dub) */
  specification: string
  /** Montážna skupina */
  workerGroupID: string
  /** Datum montáže - zobrazenie */
  displayInstallationDate: string
  installationDate: string
  installationMorning: boolean
  installationAfternoon: boolean
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


const getEntityName = (o?: Maybe<Object_Order>) => {
  const i = InvoicingData(o?.invoicingData)
  return i.invoicedEntity === 'person'
    ? `${i.lastName || '(přijmení)'} ${i.firstName || '(jméno)'}}`
    : `${i.companyName || '(název firmy'}`
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


const getOrderSummaryConfigurations = (o?: Maybe<Object_Order>): SummaryConfiguration[] => {
  const sumConf: SummaryConfiguration[] = []

  const parse = (c: Maybe<Object_OrderDoorConfiguration>): SummaryConfiguration => {
    const co = ConfigurationImport(c)
    const z = co.parameters.zdivo?.substring(0, 1) || '-'
    const u = co.parameters.umisteniPantu?.substring(0, 1) || '-'
    const b = co.parameters.barva || '-'

    return {
      ...PimcoreNode(co.id, co.key, co.fullpath),
      city: co.city || '',
      specification: `${z} / ${u} / ${b}`,
      workerGroupID: co.workerGroup?.workerGroupID || '-',
      displayInstallationDate: readInstallDate(co),
      installationMorning: co.installationMorning,
      installationAfternoon: co.installationAfternoon,
      installationDate: AppDate.Pimcore.Format(co.installationDate),
    }
  }

  if (o?.configurations) for (const c of o?.configurations) sumConf.push(parse(c))
  return sumConf
}
