/*
 * Type definitions for Authorization-oriented models.
 * Business logic regarding auth/roles of the app can be found in respective common/logic/auth source file.
 *
 * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API.md
 */

import { getUserRole } from './roles.utils'
import { UserRole } from './roles.enum'
import { WorkerGroup } from '@/models/workers/WorkerGroup'
import { BranchOffice } from '@/models/branchoffice/BranchOffice'
import { isRDOBranchOffice, isRDOWorkerGroup, PimcoreUserResponse } from '@/models/pimcore/restapi/PimcoreUserResponse'
import { API } from '@/api'


export type Login = {
  mail: string
  pass: string
}
export const Login = (mail?: string, pass?: string): Login =>
  mail && pass ? { mail, pass } : { mail: '', pass: '' }


export type JWT = {
  /** Access Token */
  token: string
  /** Refresh Token */
  refresh_token: string
}
export const JWT = (at?: string, rt?: string): JWT => at && rt
  ? { token: at, refresh_token: rt }
  : { token: '', refresh_token: '' }

export const isJWT = (o?: any): o is JWT => o?.token != null && o?.refresh_token != null


/** User data received from /api/user */
export type User = {
  id: number

  email: string
  name: string
  role: UserRole

  workerGroup?: WorkerGroup
  branchOffice?: BranchOffice
  branchOffices?: BranchOffice[]
}
export const User = (o?: PimcoreUserResponse): User => {
  const r: User = {
    id: o?.id || 0,
    email: o?.email || '',
    name: o?.name || '',
    role: getUserRole(o?.roles),
  }

  if (o) {
    for (const dataObject of o?.dataObject) {
      if (isRDOWorkerGroup(dataObject)) {
        dataObject.values.id = String(dataObject.id)
        dataObject.values.key = dataObject.key
        dataObject.values.fullpath = dataObject.fullpath
        r.workerGroup = WorkerGroup(dataObject.values)
      }
      else if (isRDOBranchOffice(dataObject)) {
        dataObject.values.id = String(dataObject.id)
        dataObject.values.key = dataObject.key
        dataObject.values.fullpath = dataObject.fullpath
        if (!r.branchOffices) r.branchOffices = []
        r.branchOffices.push(BranchOffice(dataObject.values))
      }
    }
  }

  return r
}

export const CreateUser = async (o?: PimcoreUserResponse): Promise<User> => {
  const u = User(o)
  const BOID =  u.branchOffice?.id

  if ((['branch_montage_ht', 'branch_montage_branch'] as UserRole[]).includes(u.role) && BOID) {
    const b = await API.db.branches.read(parseInt(BOID))
    if (b) u.branchOffice = b
  }

  return u
}
