import { Module } from 'vuex'
import { RootState } from './root.store'
import { getField, updateField, createHelpers } from 'vuex-map-fields'
import { mapFieldsFunction } from '@/common/custom/vuex-map-fields-customization'
import { JWT, User } from '@/common/logic/auth/auth.types'


/**
 * State diagram for this auth
 * \ DEFAULT -> LOADING -> AUTHENTICATED -> *
 * \ DEFAULT -> LOADING -> ERROR -> *
 */
export type Flag = 'INIT' | 'DEFAULT' | 'LOADING' | 'ERROR' | 'AUTH'

export type AuthState = {
  flag: Flag
  user: User
  jwt: JWT

  /** Timestamp referencing expiration of auth tokens. Relevant only if previously stored user data */
  expires: number
}

export const AuthState = (flag: Flag): AuthState => ({ flag, user: User(), jwt: JWT(), expires: 0 })

export const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: AuthState('INIT'),
  getters: { getField },
  mutations: {
    updateField,

    assignState(state, payload: AuthState) {
      Object.assign(state, payload)
    },
  },

  actions: {

    setState(context, payload: AuthState) {
      context.commit('assignState', payload)
    },

    // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  --- JWT

    setJWT(context, value: JWT) {
      context.commit('updateField', { path: 'jwt', value })
    },

    clearJWT(context) {
      context.commit('updateField', { path: 'jwt', value: JWT() })
    },

    // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  --- User

    setUser(context, value: User) {
      context.commit('updateField', { path: 'user', value })
    },

    clearUser(context) {
      context.commit('updateField', { path: 'user', value: User() })
    },

    // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  --- Other

  },
}

/** * JWT and User is READONLY! */
export const mapFieldsAuth = createHelpers({
  getterType: 'auth/getField',
  mutationType: 'auth/updateField',
}).mapFields as mapFieldsFunction<AuthState>
