import { GQLOrderActions } from '@/api/graphql/app/GQLOrderActions'
import { GQLPublicOrderQuery } from '@/api/graphql/public/GQLPublicOrder'
import { PublicAPI } from '@/api/interfaces/public/public.api.interface'


export const IPublicAPI: PublicAPI = {

  orderPreview: GQLPublicOrderQuery.execute,

  async reviewOrder(hash, action) {
    const available = await GQLOrderActions.GetPreview.publicProcedure(hash)
    if (!GQLOrderActions.GetPreview.isError(available)) {
      if (available.find(i => i.action === action && i.enabled)) {
        const result = await GQLOrderActions.ApplyPreview.publicProcedure(hash, action)
        if (!GQLOrderActions.Apply.isError(result)) {
          return result
        }
      }
    }
    return undefined
  },

}
