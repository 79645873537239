import gql from 'graphql-tag'

export default gql`
mutation DeleteAsset($id: Int!, $fullpath: String) {
  deleteAsset(
    id: $id,
    fullpath: $fullpath,
  ) {
    success
    message
  }
}
`
