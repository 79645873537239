import { Maybe } from '@/api/graphql/schema/app.graphql.types'
import { Nullable } from '@/common/custom/ts-customization'

export enum CONFIGURATION_DOOR_MODEL {
  magnum = 'Magnum',
  magnum_ext = 'Magnum EXT',
}

export enum APPLICATION_LABEL {
  title = 'Páskový dekor INOX',
  outer = 'Příplatek INOX - vnější strana',
  inner = 'Příplatek INOX - vnitřní strana',
  doubleside = 'Příplatek INOX - oboustranně',
  doorPlateName = 'Jmenovka',
  doorPlateNumber = 'Číslo',
  doorPlateNameNumber = 'Jmenovka a číslo'
}

export enum CONFIGURATION_KEY {
  model = 'model',
  vypln = 'vypln',
  umisteni = 'umisteni',
  zdivo = 'zdivo',
  kovani = 'kovani',
  aplikace = 'aplikace',
  aplikacedata_strana = 'aplikaceData.strana',
  barva_zarubne = 'barvaZarubne',
  sirka_dveri = 'sirkaDveri',
  moznost_otevirani = 'moznostOtevirani',
  umisteni_pantu = 'umisteniPantu',
  sirka_zarubne = 'sirkaZarubne',
  barva = 'barva',
  kovanidata_tvar = 'kovaniData.tvar',
  kovanidata_delka = 'kovaniData.delka',
  vzor_vyplne = 'vzorVyplne',
  typ_proskleni = 'typProskleni',
  svetlik = 'svetlik',
  svetlikdata_barva = 'svetlikData.barva',
  svetlikdata_umisteni = 'svetlikData.umisteni',
  svetlikdata_sirka = 'svetlikData.sirka',
  svetlikdata_typ_proskleni = 'svetlikData.typProskleni',
  aplikacedata_vzor = 'aplikaceData.vzor',
  customer_note_zdivo = 'customerNoteZdivo',
  customer_note = 'customerNote',
  jmenovky = 'jmenovky',
  jmenovky_text = 'jmenovkyText',
  jmenovkydata_pismo = 'jmenovkyData.pismo',
  jmenovkydata_ramecek = 'jmenovkyData.ramecek',
  jmenovkydata_tlapka = 'jmenovkyData.tlapka',
  jmenovkydata_typ = 'jmenovkyData.typ',
  cislo_na_dvere = 'cisloNaDvere',
  cislonadvere_text = 'cisloNaDvereText',
  cislonadveredata_pismo = 'cisloNaDvereData.pismo',
  cislonadveredata_ramecek = 'cisloNaDvereData.ramecek',
  cislonadveredata_typ = 'cisloNaDvereData.typ',
}


/**
 * Door Configuration Parameters
 */
export type DoorParameters = {
  umisteni: string
  model: string
  vypln: string
  sirkaDveri: string

  // ------------------------------------

  moznostOtevirani: string
  umisteniPantu: string
  /** Special field: add to note-for-customer something */
  zdivo: string
  sirkaZarubne: string
  barva: string
  barvaZarubne: string

  kovani: string
  /** if (kovani == madlo) */
  kovaniData: {
    tvar: string
    delka: string
  }

  vzorVyplne: string
  typProskleni: string

  svetlik: string
  /** if (svetlik == ano) */
  svetlikData: {
    barva: string
    umisteni: string
    sirka: string
    typProskleni: string
  }

  aplikace: string
  /** if (aplikace == ano) */
  aplikaceData: {
    vzor: string
    strana: string
  }

  customerNoteZdivo: string
  customerNote: string

  jmenovky: string
  jmenovkyText: string
  jmenovkyData: {
    pismo: string
    ramecek: string
    tlapka: string
    typ: string
  }

  cisloNaDvere: string
  cisloNaDvereText: string
  cisloNaDvereData: {
    pismo: string
    ramecek: string
    typ: string
  }
}

export const DoorParameters = (s?: Maybe<string>): DoorParameters => {
  const o: Nullable<DoorParameters> = s ? JSON.parse(s) : undefined
  return {
    umisteni: o?.umisteni || '',
    model: o?.model || '',
    vypln: o?.vypln || '',
    sirkaDveri: o?.sirkaDveri || '',
    sirkaZarubne: o?.sirkaZarubne || '',
    barva: o?.barva || '',
    barvaZarubne: o?.barvaZarubne || '',
    zdivo: o?.zdivo || '',
    aplikace: o?.aplikace || '',
    aplikaceData: {
      vzor: o?.aplikaceData?.vzor || '',
      strana: o?.aplikaceData?.strana || '',
    },
    moznostOtevirani: o?.moznostOtevirani || '',
    umisteniPantu: o?.umisteniPantu || '',
    kovani: o?.kovani || '',
    kovaniData: {
      tvar: o?.kovaniData?.tvar || '',
      delka: o?.kovaniData?.delka || '',
    },
    svetlik: o?.svetlik || '',
    svetlikData: {
      barva: o?.svetlikData?.barva || '',
      typProskleni: o?.svetlikData?.typProskleni || '',
      umisteni: o?.svetlikData?.umisteni || '',
      sirka: o?.svetlikData?.sirka || '',
    },
    vzorVyplne: o?.vzorVyplne || '',
    typProskleni: o?.typProskleni || '',
    customerNote: o?.customerNote || '',
    customerNoteZdivo: o?.customerNoteZdivo || '',
    jmenovky: o?.jmenovky || '',
    jmenovkyText: o?.jmenovkyText || '',
    jmenovkyData: {
      pismo: o?.jmenovkyData?.pismo || '',
      ramecek: o?.jmenovkyData?.ramecek || '',
      tlapka: o?.jmenovkyData?.tlapka || '',
      typ: o?.jmenovkyData?.typ || '',
    },
    cisloNaDvere: o?.cisloNaDvere || '',
    cisloNaDvereText: o?.cisloNaDvereText || '',
    cisloNaDvereData: {
      pismo: o?.cisloNaDvereData?.pismo || '',
      ramecek: o?.cisloNaDvereData?.ramecek || '',
      typ: o?.cisloNaDvereData?.typ || '',
    },
  }
}


/** Extend with inner params into string extended type */
export type DoorParamKeys = keyof DoorParameters
  | CONFIGURATION_KEY.kovanidata_tvar
  | CONFIGURATION_KEY.kovanidata_delka
  | CONFIGURATION_KEY.svetlikdata_barva
  | CONFIGURATION_KEY.svetlikdata_umisteni
  | CONFIGURATION_KEY.svetlikdata_sirka
  | CONFIGURATION_KEY.svetlikdata_typ_proskleni
  | CONFIGURATION_KEY.aplikacedata_vzor
  | CONFIGURATION_KEY.aplikacedata_strana
  | CONFIGURATION_KEY.jmenovkydata_pismo
  | CONFIGURATION_KEY.jmenovkydata_ramecek
  | CONFIGURATION_KEY.jmenovkydata_tlapka
  | CONFIGURATION_KEY.jmenovkydata_typ
  | CONFIGURATION_KEY.cislonadveredata_pismo
  | CONFIGURATION_KEY.cislonadveredata_ramecek
  | CONFIGURATION_KEY.cislonadveredata_typ
  | CONFIGURATION_KEY.jmenovky_text
  | CONFIGURATION_KEY.cislonadvere_text

// sequence - chcel som vyvolat foreach doorparameter object param, ale v konkretnom poradi
export const DoorParameterSequence: { key: DoorParamKeys; label: string }[] = [
  { key: CONFIGURATION_KEY.umisteni, label: 'Umístění dveří' },
  { key: CONFIGURATION_KEY.model, label: 'Model dveří' },
  { key: CONFIGURATION_KEY.vypln, label: 'Typ Dveří' },
  { key: CONFIGURATION_KEY.sirka_dveri, label: 'Šířka dveří' },
  { key: CONFIGURATION_KEY.moznost_otevirani, label: 'Možnost Otevírání' },
  { key: CONFIGURATION_KEY.umisteni_pantu, label: 'Umístění Pantů' },
  { key: CONFIGURATION_KEY.zdivo, label: 'Zdivo' },
  { key: CONFIGURATION_KEY.sirka_zarubne, label: 'Šířka zárubně' },
  { key: CONFIGURATION_KEY.barva, label: 'Barva dveří' },
  { key: CONFIGURATION_KEY.barva_zarubne, label: 'Barva zárubně' },
  { key: CONFIGURATION_KEY.kovani, label: 'Kování' },
  { key: CONFIGURATION_KEY.kovanidata_tvar, label: 'Typ Madla' },
  { key: CONFIGURATION_KEY.kovanidata_delka, label: 'Délka Madla' },
  { key: CONFIGURATION_KEY.vzor_vyplne, label: 'Vzor výplně' },
  { key: CONFIGURATION_KEY.typ_proskleni, label: 'Typ prosklení' },
  { key: CONFIGURATION_KEY.svetlik, label: 'Světlík' },
  { key: CONFIGURATION_KEY.svetlikdata_barva, label: 'Barvy Světlíku' },
  { key: CONFIGURATION_KEY.svetlikdata_umisteni, label: 'Umístění Světlíku' },
  { key: CONFIGURATION_KEY.svetlikdata_sirka, label: 'Šířka Světlíku' },
  { key: CONFIGURATION_KEY.svetlikdata_typ_proskleni, label: 'Prosklení Světlíku' },
  { key: CONFIGURATION_KEY.aplikace, label: 'Aplikace' },
  { key: CONFIGURATION_KEY.aplikacedata_vzor, label: 'Vzor aplikace' },
  { key: CONFIGURATION_KEY.aplikacedata_strana, label: 'Strana aplikace' },
  { key: CONFIGURATION_KEY.customer_note_zdivo, label: 'Poznámka pro zdivo' },
  { key: CONFIGURATION_KEY.customer_note, label: 'Poznámka pro zákazníka' },
  { key: CONFIGURATION_KEY.jmenovky, label: 'Hranatá jmenovka na dveře' },
  { key: CONFIGURATION_KEY.jmenovky_text, label: 'Text na jmenovku' },
  { key: CONFIGURATION_KEY.jmenovkydata_pismo, label: 'Výběr písma' },
  { key: CONFIGURATION_KEY.jmenovkydata_ramecek, label: 'Rámeček okolo jmenovky' },
  { key: CONFIGURATION_KEY.jmenovkydata_tlapka, label: 'Tlapka pro majitele mazlíčků' },
  { key: CONFIGURATION_KEY.jmenovkydata_typ, label: 'Typ jmenovky' },
  { key: CONFIGURATION_KEY.cislo_na_dvere, label: 'Kulaté číslo na dveře' },
  { key: CONFIGURATION_KEY.cislonadvere_text, label: 'Text na číslo' },
  { key: CONFIGURATION_KEY.cislonadveredata_pismo, label: 'Výběr písma' },
  { key: CONFIGURATION_KEY.cislonadveredata_ramecek, label: 'Rámeček okolo čísla' },
  { key: CONFIGURATION_KEY.cislonadveredata_typ, label: 'Typ číslovky' },
]
