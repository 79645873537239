import { Maybe, Object_ContactData } from '@/api/graphql/schema/app.graphql.types'
import { PimcoreNode } from '../pimcore/PimcoreNode'


/** Information related to service receiver. */
export type ContactData = PimcoreNode & {
  firstName: string
  lastName: string
  phone: string
  email: string
  note: string
  street?: string
  city?: string
  postcode?: string
}
export const ContactData = (o?: Maybe<Object_ContactData>): ContactData => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),
  firstName: o?.firstName || '',
  lastName: o?.lastName || '',
  phone: o?.phone || '',
  email: o?.email || '',
  note: o?.note || '',
})
