import gql from 'graphql-tag'

export default gql`
  mutation DeleteWorkerOccupation(
    $id: Int!
    $fullpath: String!
  ) {
    deleteWorkerOccupation(
      id: $id,
      fullpath: $fullpath
    ) { success }
  }
`
