import { AssemblyCalendarEntry, WorkerGroupBlocking, WorkerGroupVacation } from '@/models/customview/AssemblyCalendar'
import { WorkerGroup } from '@/models/workers/WorkerGroup'
import { AppDate } from '../utils/AppDate'

import type { BranchOffice } from '@/models/branchoffice/BranchOffice'

export type CalendarScope = 'week' | 'month' | 'year'
type CalendarControlPanelMetadataPartial = {

  /** Calendar time scope → week / month / year */
  scope: CalendarScope

  /** Date object references */
  date: Date
  dateFrom?: Date
  dateTo?: Date

  /** stringified value: YYYY-MM-DD */
  from?: string
  /** stringified value: YYYY-MM-DD */
  to?: string
}
export type CalendarControlPanelMetadata = Required<CalendarControlPanelMetadataPartial>

export const CalendarControlPanelMetadata = (scope: CalendarScope = 'week'): CalendarControlPanelMetadata => {
  const metadata: CalendarControlPanelMetadataPartial = {
    date: new Date(),
    scope,
  }
  return DateControlPanelFunctions.updateCalendarMetadata(metadata)
}

export namespace DateControlPanelFunctions {

  export const updateCalendarMetadata = (metadata: CalendarControlPanelMetadataPartial): CalendarControlPanelMetadata => {
    metadata.date.setHours(12, 0, 0)

    if (metadata.scope === 'week') {
      metadata.dateFrom = AppDate.Util.getFirstDateOfWeek(metadata.date)
      metadata.dateTo = AppDate.Util.getLastDateOfWeek(metadata.date)
    }
    else if (metadata.scope === 'month') {
      metadata.dateFrom = AppDate.Util.getFirstDateOfMonth(metadata.date)
      metadata.dateTo = AppDate.Util.getLastDateOfMonth(metadata.date)
    }
    else {
      metadata.dateFrom = AppDate.Util.getFirstDateOfYear(metadata.date)
      metadata.dateTo = AppDate.Util.getLastDateOfYear(metadata.date)
    }

    metadata.from = AppDate.Format.str(metadata.dateFrom)
    metadata.to = AppDate.Format.str(metadata.dateTo)
    return metadata as CalendarControlPanelMetadata
  }


  export const move = (metadata: CalendarControlPanelMetadata, direction: 'prev' | 'next') => {
    const d = new Date(AppDate.Format.str(
      {
        'week': AppDate.Util.getFirstDateOfWeek(metadata.date),
        'month': AppDate.Util.getFirstDateOfMonth(metadata.date),
        'year': AppDate.Util.getFirstDateOfYear(metadata.date),
      }[metadata.scope]
    ))

    if (metadata.scope === 'week') d.setDate(d.getDate() + (direction === 'next' ? 7 : -7))
    else if (metadata.scope === 'month') d.setMonth(d.getMonth() + (direction === 'next' ? 1 : -1))
    else d.setFullYear(d.getFullYear() + (direction === 'next' ? 1 : -1))

    metadata.date = d
    updateCalendarMetadata(metadata)
  }

  export const moveByDatepicker = (metadata: CalendarControlPanelMetadata) => {
    updateCalendarMetadata(metadata)
  }

}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

export enum TIME_SPAN {
  NONE = 'none',
  AM = 'am',
  PM = 'pm',
  EVENING = 'evening',
  ALL = 'all',
}

// export type WeekdayCellTime = 'AM' | 'PM'
export type EventType = 'assembly' | 'vacation' | 'blocking'
export type EventTimespan = 'none' | 'am' | 'pm' | 'evening' | 'all'

export const EventTimespan = (am: boolean, pm: boolean, evening = false): EventTimespan => {
  if (am && pm) return 'all'
  if (am) return 'am'
  if (pm) return 'pm'
  if (evening) return 'evening'
  return 'none'
}

export const eventTimespanPrint = (ets: EventTimespan) => {
  switch (ets) {
    case 'am': return 'cca 7:30'
    case 'pm': return ' cca 12:30'
    case 'evening': return ' cca 17:00'
    case 'all': return '7:30 celý den' // ráno a odpoledne
    default: return ''
  }
}

export type AssemblyCalendarEvent = {
  timespan: EventTimespan
  type: EventType

  entry?: AssemblyCalendarEntry
  vacation?: WorkerGroupVacation
  blocking?: WorkerGroupBlocking
  selectedDoorLabel?: string
  selectedDoorLabelExtended?: string
  branchOffice?: BranchOffice
}

export type EventCell = {
  date: string
  isToday: boolean
  workerGroup: WorkerGroup
  events: AssemblyCalendarEvent[]
}

export const adjustColor = (color: string, amount: number) => {
  // https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
}

export const isCellAvailable = (timespan: EventTimespan, cell: EventCell) => {
  switch (timespan) {
    // ak je kliknuta bunka ranna, a dana montazna skupina vtedy neoperuje, tak je blocked
    case 'am': return cell.workerGroup.availableMorning

    // ak je bunka poobede + vtedy mont.sk. neoperuje, blocked
    case 'pm': return cell.workerGroup.availableAfternoon

    // momentalne tento stav nenastane.
    case 'all': return cell.workerGroup.availableMorning && cell.workerGroup.availableAfternoon

    default: return true
  }
}

export const workGroupIsAvailable = (wg: WorkerGroup, dayIndex: number) => {
  if (wg.monday && dayIndex === 1) return false
  if (wg.tuesday && dayIndex === 2) return false
  if (wg.wednesday && dayIndex === 3) return false
  if (wg.thursday && dayIndex === 4) return false
  if (wg.friday && dayIndex === 5) return false
  if (wg.saturday && dayIndex === 6) return false
  return true
}
