import gql from 'graphql-tag'

export default gql`
mutation UpdateProtocolData(
  $fullpath: String!,
  $input: UpdateProtocolDataInput!,
) {
  updateProtocolData(
    fullpath: $fullpath,
    input: $input
  ) {
    success
    message
    output {
      id
      fullpath
    }
  }
}
`
