//
// * ...
//

export const endpointBase = process.env.VUE_APP_URL

/** Endpoints for app. */
export const endpoints = {
  main: process.env.VUE_APP_URL,

  /** * Auth REST API */
  auth: {

    /**
     * * POST
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#obtain-token-refresh-token
     * */
    login: `${endpointBase}/api/login`,


    /**
     * * GET
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#users-information
     * */
    user: `${endpointBase}/api/user`,


    /**
     * * POST
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#refresh-token
     * */
    refresh: `${endpointBase}/api/token/refresh`,


    // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


    /**
     * * POST
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#registration-role_api_admin-role_api_branch_admin-role_pimcore_admin
     * */
    register: `${endpointBase}/api/user/register`,


    /**
     * * POST
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#user-edit-role_api_admin-role_api_branch_admin-role_pimcore_admin
     * */
    user_edit: `${endpointBase}/api/user/edit`,


    /**
     * * DELETE
     * */
    user_delete: `${endpointBase}/api/user/:id`,

    /**
     * * GET
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#users-list-role_api_admin-role_api_branch_admin-role_pimcore_admin
     * */
    user_list: `${endpointBase}/api/user/list`,


    // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---


    /**
     * * POST
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#user-password-reset
     * */
    password_reset: `${endpointBase}/api/user/password-reset`,


    /**
     * * POST
     * * https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/blob/main/REST_API_user.md#user-password-change
     * */
    password_change: `${endpointBase}/api/user/password-change`,


    /**
     * * POST
     * * ...
     */
    password_update: `${endpointBase}/api/user/password-update`,

  },

  assets: {
    /** Use either configurator, or prices to get the JSON data */
    /** debug version only for test, rename for release */
    config: (v: 'configurator' | 'prices') => `${endpointBase}/configuration/${v}.json?${process.env.VERSION}`,

    config_order: (v: string) => `${endpointBase}${v}?${process.env.VERSION}`,
    /**
     * Construct Pimcore Datahub path/fullpath parameter.
     *
     * @param path may be:
     * * empty string, used to create base asset folder for the order
     * * pure orderNumber ('22001234')
     * * configuration attachment folder ('22001234/prilohy-c4fe')
     *
     * @returns '/objednavky/...'
     */
    orders: (path?: string) => `/objednavky${path ? `/${path}` : ''}`,
    ordersFullPath: (path?: string) => `${path ? `${path}` : ''}`,
  },

  ares: (ico: string) => `${process.env.VUE_APP_ARES_URL}/${ico}`,

  orderPdf: (number: string) => `${process.env.VUE_APP_PDF_ORDER}/${number}/private`,
  orderPdfPublic: (number: string) => `${process.env.VUE_APP_PDF_ORDER}/${number}/public`,

  serviceRequestPdf: (serviceRequestId: string): string => `${endpointBase}/api/pdf/service-protocol/${serviceRequestId}`,

  serviceRequestProtocolTemplate: (serviceRequestId: string): string => `${endpointBase}/api/pdf/service-protocol/${serviceRequestId}/empty`,

  handoverPdf: (confId: string) => `${process.env.VUE_APP_PDF_PROTOCOLS}/${confId}`,

  settings: {
    list: `${endpointBase}/api/settings/list`,

    new: `${endpointBase}/api/settings/new`,

    edit: `${endpointBase}/api/settings/edit`,

    item: (key: string) => `${endpointBase}/api/settings/${key}`,

  },
}
