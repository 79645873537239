import axios from 'axios'
import { endpoints } from '@/common/constants/endpoints'
import { ulog } from '@/common/utils/AppConsole'
import { Nullable } from '@/common/custom/ts-customization'

export const aresSearch = async (ico: string): Promise<Nullable<string>> => axios
  .get(endpoints.ares(ico))
  .then(r => r.status === 200
    ? <string> r.data
    : ulog('e', 'aresSearch failed', JSON.stringify({ ico }), r)
  )
  .catch(e => ulog('e', 'aresSearch exception', JSON.stringify({ ico }), e))
