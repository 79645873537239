import gql from 'graphql-tag'

export default gql`
query ListWorkerGroups {
  getWorkerGroupListing (sortBy: "priority", sortOrder: "ASC") {
    edges {
      node {
        id, key, fullpath,
        workerGroupID, availableMorning, availableAfternoon, availableEvening, color,
        certificate, address, ratePerKm, commission, groupIndex,
        leadingWorker {
          ...on object_Worker { id, key, fullpath, firstName, lastName, phone, email }
        },
        workers {
          ...on object_Worker { id, key, fullpath, firstName, lastName, phone, email }
        },

        apiUser {
          ...on object_ApiUser {
            id, key, fullpath,
            name, email, passwordResetHash
          }
        },
        monday, tuesday, wednesday, thursday, friday, saturday, sunday,
      }
    }
  }
}
`
