import { mapFieldsFunction } from '@/common/custom/vuex-map-fields-customization'
import { Module } from 'vuex'
import { getField, updateField, createHelpers } from 'vuex-map-fields'
import { RootState } from './root.store'

export type ModalState = {

  /** Otvaranie dialogu pre zmenu hesla (views/shared/PasswordChange) (trigger from UserNav) */
  dialogPasswordChange: boolean

}

export const modalModule: Module<ModalState, RootState> = {
  namespaced: true,

  state: {
    dialogPasswordChange: false,
  },

  getters: { getField },
  mutations: { updateField },
}

export const mapFieldsModal = createHelpers({
  getterType: 'modal/getField',
  mutationType: 'modal/updateField',
}).mapFields as mapFieldsFunction<ModalState>
