import { Object_BranchOffice, Object_WorkerGroup } from '@/api/graphql/schema/app.graphql.types'
import { BranchOffice } from '@/models/branchoffice/BranchOffice'
import { WorkerGroup } from '@/models/workers/WorkerGroup'
import { PimcoreNode } from '../PimcoreNode'
import { PimcoreRole } from './PimcoreApiUserRole'


type ResponseDataObject = {
  className: string
  id: number
}

export type RDO_ApiUser = ResponseDataObject & PimcoreNode & {
  values: {
    workerGroup?: WorkerGroup
    branchOffice?: BranchOffice
  }
}

export type RDO_RefreshToken = ResponseDataObject & PimcoreNode & { values: { refreshToken: string } }

export type RDO_WorkerGroup = ResponseDataObject & PimcoreNode & { values: Object_WorkerGroup }
export type RDO_BranchOffice = ResponseDataObject & PimcoreNode & { values: Object_BranchOffice }

export type RDO = RDO_ApiUser | RDO_RefreshToken | RDO_WorkerGroup | RDO_BranchOffice
export const isRDOApiUser = (o: RDO): o is RDO_ApiUser => o.className === 'ApiUser'
export const isRDORefreshToken = (o: RDO): o is RDO_RefreshToken => o.className === 'ApiRefreshToken'
export const isRDOWorkerGroup = (o: RDO): o is RDO_WorkerGroup => o.className === 'WorkerGroup'
export const isRDOBranchOffice = (o: RDO): o is RDO_BranchOffice => o.className === 'BranchOffice'


/** User data received from /api/user */
export type PimcoreUserResponse = {

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  --- Metadata

  /** REST API Return Code */
  code?: number
  /** REST API Return Message */
  message?: string
  /** Numbered unique identifier of Pimcore User */
  id: number

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  --- Main Data

  email: string
  name: string
  roles: PimcoreRole[]
  dataObject: RDO[]

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  --- ONLY IF PASSWORD-UPDATE
  token?: string
  refresh_token?: string

}


// export const PimcoreApiUser = (o?: User): PimcoreApiUser => ({
//   id: o?.id || 0,
//   email: o?.email || '',
//   name: o?.name || '',
//   password: undefined,
//   roles: getPimcoreRole(o?.role),
//   dataObject: undefined,
// })
