import { Object_ApiUser } from '@/api/graphql/schema/app.graphql.types'
import { Maybe } from '@/api/graphql/schema/generic.types'
import { BranchOffice } from '../branchoffice/BranchOffice'
import { WorkerGroup } from '../workers/WorkerGroup'
import { PimcoreNode } from './PimcoreNode'

export type PimcoreApiUser = PimcoreNode & {
  name: string
  email: string
  readonly passwordResetHash: string
  workerGroup?: WorkerGroup
  branchOffice?: BranchOffice
}
export const PimcoreApiUser = (o?: Maybe<Object_ApiUser>): PimcoreApiUser => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),
  name: o?.name || '',
  email: o?.email || '',
  passwordResetHash: o?.passwordResetHash || '',
  workerGroup: o?.workerGroup?.length ? WorkerGroup(o.workerGroup[0]) : undefined,
  branchOffice: o?.branchOffice?.length ? BranchOffice(o.branchOffice[0]) : undefined,
})
