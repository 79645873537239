import { storeGetter } from '@/store/store.getters'
import { User } from '@/common/logic/auth/auth.types'
import { UserRoleGroup, UserRoleGroupEnum } from '@/common/logic/auth/roles.enum'

const AuthResolver  = {
  install: (Vue) => {
    const getUser = () => {
      return storeGetter.auth<User>('user')
    }

    const isInRole = (role: string) =>  {
      return UserRoleGroup(getUser().role) === role
    }

    Vue.prototype.$auth = Vue.observable({
      user: () => {
        return getUser()
      },
      hasBranchRole: () => {
        return isInRole(UserRoleGroupEnum.branch)
      },
      hasWorkerRole: () => {
        return isInRole(UserRoleGroupEnum.worker)
      },
      hasHtRole: () => {
        return isInRole(UserRoleGroupEnum.ht)
      },
    })
  },
}

export default AuthResolver
