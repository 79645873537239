import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // https://stackoverflow.com/questions/45201014/how-to-handle-anchors-bookmarks-with-vue-router
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 200, y: 200 },
        behavior: 'smooth',
      }
    }
    else if (savedPosition) {
      return savedPosition
    }
    else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
