import { GQLAssemblyCalendar } from '@/api/graphql/app/GQLAssemblyCalendar'
import { GQLWorkerOccupancy } from '@/api/graphql/app/workerOccupancy/GQLWorkerOccupancy'
import { AssemblyCalendarEntry, AssemblyCalendarParams, AssemblyCalendarViewModel, WorkerGroupBlocking, WorkerGroupBlockingsImport, WorkerGroupVacation, WorkerGroupVacationsImport } from '@/models/customview/AssemblyCalendar'
import { WorkerGroup } from '@/models/workers/WorkerGroup'


/**
 * * GQL get assembly calendar fetch implementation
 *
 * ! TODO: vlozit from+to do funkcii blocking/vacations import
 *
 * @param params parameters for calling assemblycalendar and workeroccupancy queries
 * @returns merged assembly calendar viewmodel
 */
export const getAssemblyCalendar = async (params: AssemblyCalendarParams) => {
  const map: AssemblyCalendarViewModel = {}
  const rCalendar = await GQLAssemblyCalendar.Get.execute(params)
  const rOccupations = await GQLWorkerOccupancy.Get.execute(params)

  if (rCalendar) {
    for (const cItem of rCalendar) {
      if (!cItem) continue
      const wg = WorkerGroup(cItem.workerGroup)
      const id = wg.id as string
      const a: AssemblyCalendarEntry[] = []

      if (cItem.assemblies?.length) for (const i of cItem.assemblies) a.push(AssemblyCalendarEntry(i))
      if (!map[id]) map[id] = { workerGroup: wg, assemblies: a, blockings: [], vacations: [] }
      else map[id].assemblies.push(...a)
    }
  }

  if (rOccupations) {
    for (const oItem of rOccupations) {
      if (!oItem) continue
      const wg = WorkerGroup(oItem.workerGroup)
      const id = wg.id as string
      const b: WorkerGroupBlocking[] = []
      const v: WorkerGroupVacation[] = []

      if (oItem.blockings?.length) b.push(...WorkerGroupBlockingsImport(oItem.blockings))
      if (oItem.vacations?.length) v.push(...WorkerGroupVacationsImport(oItem.vacations))

      if (!map[id]) {
        map[id] = { workerGroup: wg, assemblies: [], blockings: b, vacations: v }
      }
      else {
        map[id].blockings.push(...b)
        map[id].vacations.push(...v)
      }
    }
  }


  return map
}
