import gql from 'graphql-tag'

export default gql`
query GetWorkerGroup($id: Int!) {
  getWorkerGroup(id: $id) {
    id, key, fullpath,
    workerGroupID, availableMorning, availableAfternoon, availableEvening, color,
    certificate, address, ratePerKm, commission, groupIndex,
    monday, tuesday, wednesday, thursday, friday, saturday, sunday,

    leadingWorker {
      ...on object_Worker { id, key, fullpath, firstName, lastName, phone, email }
    },
    workers {
      ...on object_Worker { id, key, fullpath, firstName, lastName, phone, email }
    },

    apiUser {
      ...on object_ApiUser {
        id, key, fullpath,
        name, email, passwordResetHash
      }
    },
  }
}
`
