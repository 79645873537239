//
// Pimcore metadata that are always bundled into our data objects
//

import { Asset, Maybe, Object_ApiUser, Object_BranchOffice_ApiUser, Object_ContactData, Object_InvoicingData, Object_OrderDoorConfiguration, Object_ProtocolData, Object_Worker, Object_WorkerGroup, Object_WorkerGroup_ApiUser } from '@/api/graphql/schema/app.graphql.types'
import { get, Nullable } from '@/common/custom/ts-customization'

export type PimcoreNode = {
  /** PimcoreNode attribute - defined as a string, but may need to be transformed into Int in case of using this as GQL query/mutation GQL function */
  readonly id?: string
  /** the key of this pimcore object, human readable */
  readonly key?: string
  /** always as "/objednavky/YYYY/MM/_order_id_" */
  readonly fullpath?: string
}
export const PimcoreNode = (id?: Maybe<string>, key?: Maybe<string>, fullpath?: Maybe<string>): PimcoreNode => ({
  id: get(id, undefined),
  key: get(key, undefined),
  fullpath: get(fullpath, undefined),
})

export type PimcoreImportable =
  | Maybe<Object_ContactData>
  | Maybe<Object_InvoicingData>
  | Maybe<Object_OrderDoorConfiguration>
  | Maybe<Asset>
  | Maybe<Object_Worker>
  | Maybe<Object_WorkerGroup>
  | Maybe<Object_WorkerGroup_ApiUser>
  | Maybe<Object_BranchOffice_ApiUser>
  | Maybe<Object_ApiUser>
  | Maybe<Object_ProtocolData>

export const PimcoreNodeImport = (o: PimcoreImportable): PimcoreNode => ({
  id: o?.id || undefined,
  fullpath: o?.fullpath || undefined,
  key: (<any>o)?.key || undefined,
})

/** https://stackoverflow.com/questions/72267817/create-an-interface-with-only-the-mutable-fields-of-a-larger-interface */
type Writeable<T> = { -readonly [P in keyof T]: T[P] }

/** Strip the pimcore node metadata. required for gql.create */
export const PimcoreNodeStrip = <T extends PimcoreNode>(pimcoreObject: Writeable<T>): T => {
  const objectCopy = { ...pimcoreObject }
  delete objectCopy.id
  delete objectCopy.fullpath
  delete objectCopy.key
  return objectCopy
}

export const PimcoreNodeAssign = <T extends PimcoreNode>(o: Writeable<T>, node?: PimcoreNode): Nullable<T> => {
  if (!node) return undefined

  o.id = node.id
  o.key = node.key
  o.fullpath = node.fullpath

  return o
}
