import { ulog } from '@/common/utils/AppConsole'
import { ProtocolData, ProtocolDataExport, ProtocolDataExportAdditionalPictures } from '@/models/order/configuration/ProtocolData'
import { PimcoreNodeAssign, PimcoreNodeImport } from '@/models/pimcore/PimcoreNode'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { CreateProtocolDataResult, UpdateProtocolDataInput } from '../../schema/app.graphql.types'
import createProtocolData from './createProtocolData'
import updateProtocolData from './updateProtocolData'


export namespace GQLProtocolData {

  export namespace Create {

    const mutation = createProtocolData

    export type Variables = {
      key: string
      parentId: number
      input: UpdateProtocolDataInput
    }

    export type Data = {
      createProtocolData: CreateProtocolDataResult
    }

    export const execute = (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.createProtocolData?.success && r.data.createProtocolData.output
        ? PimcoreNodeImport(r.data.createProtocolData.output)
        : ulog('e', 'GQLProtocolData(C) failed', r)
      )
      .catch(e => ulog('e', 'GQLProtocolData(C) exception', e))


    export const procedure = async (key: string, configParentId: string, data: ProtocolData) =>
      PimcoreNodeAssign<ProtocolData>(
        data,
        await execute({ key, parentId: parseInt(configParentId), input: ProtocolDataExport(data) })
      )

  }

  export namespace Update {

    const mutation = updateProtocolData

    export type Variables = {
      fullpath: string
      input: UpdateProtocolDataInput
    }

    export type Data = {
      updateProtocolData: CreateProtocolDataResult
    }

    export const execute = (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.updateProtocolData?.success && r.data.updateProtocolData.output
        ? PimcoreNodeImport(r.data.updateProtocolData.output)
        : ulog('e', 'GQLProtocolData(U) failed', r)
      )
      .catch(e => ulog('e', 'GQLProtocolData(C) exception', e))


    export const procedure = async (fullpath: string, data: ProtocolData) =>
      PimcoreNodeAssign<ProtocolData>(
        data,
        await execute({ fullpath, input: ProtocolDataExportAdditionalPictures(data) })
      )

  }

}
