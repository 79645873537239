import gql from 'graphql-tag'
import { CreateAssetFolderResult, DeleteAssetFolderResult } from '../schema/app.graphql.types'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { rlog } from '@/common/utils/AppConsole'

export namespace GQLAssetFolder {

  export namespace Create {

    const mutation = gql`
      mutation createAssetFolder($filename: String!, $parentId: Int!) {
        createAssetFolder(filename: $filename, parentId: $parentId) {
          success, message, id
        }
      }
    `

    export type Variables = {
      /** filename that must be usually lowercase */
      filename: string
      /** Path that should be used in terms of Pimcore backend, and as a GQL createAsset mutation variable parameter */
      parentId: number
    }

    export type Data = {
      createAssetFolder: CreateAssetFolderResult
    }

    export const procedure = (parentId: number, filename: string) => appClient
      .mutate<Data, Variables>({ mutation, variables: { filename: filename.toLowerCase(), parentId } })
      .then(r => r.data?.createAssetFolder.success && r.data.createAssetFolder.id
        ? r.data.createAssetFolder.id
        : rlog('e', 0, 'MutateAssetFolder(C) failed', r)
      )
      .catch(e => rlog('e', 0, 'GQLAssetFolder(C) exception', e))
  }

  export namespace Delete {

    const mutation = gql`
      mutation deleteAssetFolder($id: Int!) {
        deleteAssetFolder(id: $id) { success, message }
      }
    `

    export type Variables = {
      id: number
    }

    export type Data = {
      deleteAssetFolder: DeleteAssetFolderResult
    }


    export const procedure = async (id: number) => appClient
      .mutate<Data, Variables>({ mutation, variables: { id } })
      .then(r => r.data?.deleteAssetFolder.success
        ? true
        : rlog('e', false, 'GQLAssetFolder(D) failed', r)
      )
      .catch(e => rlog('e', false, 'GQLAssetFolder(D) exception', e))
  }

}
