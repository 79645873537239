import moment from 'moment'

const DateFormatterPlugin  = {
  install: (Vue) => {
    const format = (date: Date, format: string) => {
      return moment(date).format(format).toString()
    }

    Vue.prototype.$dateFormatter = {
      dateWithTime: (date : string | Date) => {
        if (typeof date === 'string') {
          date = new Date(date)
        }

        return format(date, 'DD.MM.YYYY HH:mm:ss')
      },
      date: (date : string | Date) => {
        if (typeof date === 'string') {
          date = new Date(date)
        }

        return format(date, 'DD.MM.YYYY')
      },
    }
  },
}

export default DateFormatterPlugin
