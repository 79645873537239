// * Pseudo Random Generation Functions * //

import { Order } from '@/models/order/Order'
import { AppDate } from './AppDate'

/**
 * Create empty array with given size, fills it with random values 0-15, transforms to hex and concatenates into single string.
 */
const KeyGen = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

export const RngKey4 = () => KeyGen(4)
export const RngKey6 = () => KeyGen(6)
export const RngKey8 = () => KeyGen(8)
export const RngKey16 = () => KeyGen(16)

/**
 * * Generates database key of newly created order
 * ! Pattern: YYMMDD-(lastname-firstname | companyname)
 * @param order
 * @returns
 */
export const KeyGenOrder = (order: Order) => {
  const d = AppDate.struct()

  const entityName = order.invoicingData.invoicedEntity === 'person'
    ? `${order.invoicingData.lastName}-${order.invoicingData.firstName}`
    : `${order.invoicingData.companyName}`

  return `${d.yearShort}${d.month}${d.day}-${entityName}`
}
