import { User } from '@/common/logic/auth/auth.types'
import { getPimcoreRole } from '@/common/logic/auth/roles.utils'
import { PimcoreRole } from './PimcoreApiUserRole'
import { clog } from '@/common/utils/AppConsole'

export type PimcoreUserRequest = {

  email: string
  password?: string
  name: string
  workerGroupId?: number
  branchOfficeId?: number
  roles: PimcoreRole[]
  branchOfficesId?: number[]
}
export const PimcoreUserRequest = (o?: User, password?: string): PimcoreUserRequest => {
  const r: PimcoreUserRequest = {
    email: o?.email || '',
    name: o?.name || '',
    roles: getPimcoreRole(o?.role),
  }

  if (password) r.password = password
  if (o?.workerGroup) r.workerGroupId = parseInt(o.workerGroup.id!)
  if (o?.branchOffice) r.branchOfficeId = parseInt(o.branchOffice.id!)
  if (o?.branchOffices) r.branchOfficesId = o.branchOffices.map(a => Number(a.id))

  clog(`CREATED THIS REQUEST ${r}`)
  return r
}
